define("inboxfront/routes/workflows/edit", ["exports", "inboxfront/routes/common", "inboxfront/helpers/countries-list", "inboxfront/gql/query"], function (_exports, _common, _countriesList, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var WORKFLOW = 2;

  var _default = _common.default.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    emoji: Ember.inject.service(),
    workflows: Ember.inject.service(),
    users: Ember.inject.service(),
    tasks: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.id == null) {
        this.transitionTo('workflows');
      } else {
        var queries = [this.users.getPropertyUsers(), this.ajax.query(_query.default.OPTIONS), this.workflows.getById(params.id), this.ajax.query(_query.default.ROOMS_FLOW), this.tasks.getIssueTypes()];
        this.emoji.initEmojis();
        return Ember.RSVP.all(queries).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 5),
              propertyUsers = _ref2[0],
              ruleOptions = _ref2[1],
              workflow = _ref2[2],
              rooms = _ref2[3],
              issueTypes = _ref2[4];

          console.log('EDIT WF', workflow);

          if (!workflow) {
            _this.transitionTo('workflows');
          } else {
            var users = propertyUsers.map(function (u) {
              return {
                pk: u.pk,
                fullName: "".concat(u.firstName, " ").concat(u.lastName)
              };
            });

            var flow = _this.workflows.deserialize(workflow, workflow.departments, workflow.emailAccounts, users);

            return {
              flow: flow,
              propertyUsers: propertyUsers,
              ruleOptions: ruleOptions,
              issueTypes: issueTypes,
              rooms: rooms
            };
          }
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('widget', this.users.widgetSettings());
      var flow = model.flow;
      Ember.set(flow, 'triggers', []);
      var ruleOptions = JSON.parse(model.ruleOptions);
      controller.set('ruleOptions', ruleOptions);
      controller.set('flow', flow);
      controller.set('flowId', flow.id);
      controller.set('blocks', flow.blocks);
      controller.set('isActiveFlow', flow.isActive);
      controller.set('flowName', flow.name);
      controller.set('rulesConditions', []);
      var rules = [];

      if (flow.trigger && flow.trigger.conditions && flow.trigger.conditions.any) {
        var rulesObj = this.buildRules(flow.trigger.conditions.any, rules, 'OR');
        rulesObj && rulesObj.forEach(function (rule) {
          rules.addObject(rule);
        });
      }

      rules[rules.length - 1] && (rules[rules.length - 1].operator = 'AND');

      if (flow.trigger && flow.trigger.conditions && flow.trigger.conditions.all) {
        var _rulesObj = this.buildRules(flow.trigger.conditions.all, rules, 'AND');

        _rulesObj && _rulesObj.forEach(function (rule) {
          rules.addObject(rule);
        });
      }

      rules[rules.length - 1] && (rules[rules.length - 1].operator = '');
      controller.set('triggerRules', rules);
      controller.set('conditionOptions', {
        'Status': [{
          id: "OPEN",
          name: this.intl.t('status.open')
        }, {
          id: "RE_OPENED",
          name: this.intl.t('status.re_opened')
        }, {
          id: "IN_PROGRESS",
          name: this.intl.t('status.in_progress')
        }, {
          id: "RESOLVED",
          name: this.intl.t('status.resolved')
        }, {
          id: "CLOSED",
          name: this.intl.t('status.closed')
        }, // {id: "ARCHIVED", name: this.intl.t('status.archived')},
        {
          id: "DELETED",
          name: this.intl.t('status.deleted')
        }],
        'Priority': [{
          id: "URGENT",
          name: this.intl.t('priorities.urgent')
        }, {
          id: "HIGH",
          name: this.intl.t('priorities.high')
        }, {
          id: "MEDIUM",
          name: this.intl.t('priorities.medium')
        }, {
          id: "LOW",
          name: this.intl.t('priorities.low')
        }],
        'Issue Type': model.issueTypes,
        'Source': [{
          id: "NA",
          name: "NA"
        }, {
          id: "PHONE",
          name: "Phone"
        }, {
          id: "LIVECHAT",
          name: "Live Chat"
        }, {
          id: "WECHAT",
          name: "WeChat"
        }, {
          id: "TELEGRAM",
          name: "Telegram"
        }, {
          id: "LINE",
          name: "LINE"
        }, {
          id: "TWITTER",
          name: "Twitter DM"
        }, {
          id: "WHATSAPP",
          name: "WhatsApp"
        }, {
          id: "FACEBOOK",
          name: "Facebook"
        }, {
          id: 'INSTAGRAM',
          name: 'Instagram'
        }],
        'Department': flow.departments,
        'Assignee': flow.users,
        'Attachment': [{
          name: 'Is true'
        }, {
          name: 'Is false'
        }],
        'Channel/source': [{
          id: "LIVECHAT",
          name: "Live Chat"
        }, {
          id: "WECHAT",
          name: "WeChat"
        }, {
          id: "TELEGRAM",
          name: "Telegram"
        }, {
          id: "LINE",
          name: "LINE"
        }, {
          id: "TWITTER",
          name: "Twitter DM"
        }, {
          id: "WHATSAPP",
          name: "WhatsApp"
        }, {
          id: "FACEBOOK",
          name: "Facebook"
        }, {
          id: 'INSTAGRAM',
          name: 'Instagram'
        }, {
          id: 'SHOPIFY',
          name: 'Shopify'
        }, {
          id: 'BIGCOMMERCE',
          name: 'BigCommerce'
        }],
        'Country': _countriesList.COUNTRIES_LIST,
        'Refund': [{
          name: 'Is true'
        }, {
          name: 'Is false'
        }],
        'Cancel': [{
          name: 'Is true'
        }, {
          name: 'Is false'
        }],
        'Profile qualification': [{
          name: 'Is complete'
        }, {
          name: 'Is incomplete'
        }]
      });
      controller.set('checkCondition', {
        id: 1,
        title: this.intl.t('matches_all_conditions')
      });
      controller.set('rule', {});
      controller.set('commentModules', [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }]);
      controller.set('chatModules', [{
        id: 'rooms',
        label: 'Teams'
      }, {
        id: 'users',
        label: 'Direct Messages'
      }]);
      controller.set('tagModules', [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }]);
      controller.set('optionsEditor', {
        actions: ['bold', 'italic', 'underline', 'strikethrough', 'heading1', 'heading2', 'olist', 'ulist']
      });
      controller.set('actionsFlow', [{
        type: 'assign',
        label: 'Assign to'
      }, {
        type: 'tag',
        label: 'Tag the visitor'
      }, {
        type: 'contacts',
        label: 'Add visitor to Contacts'
      }, {
        type: 'close',
        label: 'Close the conversation'
      }, {
        type: 'create_ticket',
        label: 'Create ticket'
      }]);
      controller.set('triggerOptions', {
        variables: ['Ticket/Task', 'Conversation']
      });
      controller.set('someText', '{{..}}');
      controller.set('subjVariables', ['Ticket Subject', 'Order ID', 'Ticket Issue Type', 'Ticket Source']);
      controller.set('bodyVariables', ['Customer Name', 'Customer Email', 'Issue Type', 'Source', 'Assignee']);
      controller.set('toVariables', ['Customer Email']);
      controller.set('periodTimes', ['days', 'hours', 'minutes']);
      controller.set('conditionRules', {
        'Ticket/Task': ['Status', 'Priority', 'Issue Type', 'Source', 'Department', 'Assignee', 'Attachment'],
        'Conversation': ['Channel/source', 'Assignee', 'Country', 'Refund', 'Cancel', 'Profile qualification']
      });
      controller.set('conditionOperators', ['AND', 'OR']);
    },
    buildRules: function buildRules(flow, rules, operator) {
      var options = {
        'Ticket/Task': ['Is created', 'Is updated', 'Is deleted', 'Is idled'],
        'Conversation': ['New incoming', 'Visitor has responded', 'Is idled']
      };
      var selectedColumn = 'Ticket/Task';
      var selectedCondition;
      var idledPeriod = 0;
      var idledPeriodTimes = 'minutes';
      var arrayRules = [];
      flow.forEach(function (r) {
        switch (r.name) {
          case 'age':
            selectedColumn = 'Ticket/Task';
            break;

          case 'idled':
            if (r.module) {
              selectedColumn = 'Conversation';
            } else {
              selectedColumn = 'Ticket/Task';
            }

            break;

          case 'is_new_incomming':
            selectedColumn = 'Conversation';
            selectedCondition = 'New incoming';
            break;

          case 'visitor_has_responded':
            selectedColumn = 'Conversation';
            selectedCondition = 'Visitor has responded';
            break;
        }

        switch (r.operator) {
          case 'greater_than':
            selectedCondition = 'Is idled';
            idledPeriod = +r.value;

            if (idledPeriod >= 3600) {
              idledPeriodTimes = 'days';
              idledPeriod = Math.floor(idledPeriod / 3600);
            } else if (idledPeriod >= 60) {
              idledPeriodTimes = 'hours';
              idledPeriod = Math.floor(idledPeriod / 60);
            }

            break;

          case 'equal_to':
            selectedCondition = r.value === 'created' ? 'Is created' : r.value === 'updated' ? 'Is updated' : 'Is deleted';
        }

        arrayRules.addObject({
          selectedColumn: selectedColumn,
          selectedCondition: selectedCondition,
          idledPeriod: idledPeriod,
          idledPeriodTimes: idledPeriodTimes,
          variables: ['Ticket/Task', 'Conversation'],
          operator: operator,
          ruleOptions: options[selectedColumn]
        });
      });
      return arrayRules;
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.controller.resetView && this.controller.resetView();

        this._super(transition);
      }
    }
  });

  _exports.default = _default;
});
define("inboxfront/controllers/flows/index", ["exports", "jquery", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _jquery, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    router: Ember.inject.service(),
    sliceData: Ember.inject.service(),
    FLOWSPERPAGE: 15,
    observerPage: Ember.observer('allFlows', 'pageNum', function () {
      if (this.get('allFlows')) {
        this.set('flowsOnPage', this.get('sliceData').getSliceData(this.get('allFlows'), this.get('pageNum'), this.FLOWSPERPAGE));
      }
    }),
    contentClassName: Ember.computed('isShowTemplateFlow', function () {
      return this.get('isShowTemplateFlow') ? 'template' : '';
    }),
    firstBlock: Ember.computed('blocks.[]', function () {
      return this.get('blocks').findBy('parent', null);
    }),
    componentsFlow: Ember.computed('selectedFlowTemplate.{type,objectType}', 'selectedFlowTemplate', 'selectedComponent', function () {
      return this.get('flowComponents');
    }),
    deserializeFlow: function deserializeFlow(bl) {
      var blocks = [];
      bl.forEach(function (b) {
        if (b.parent) {
          var p = bl.findBy('id', b.parent.id);

          if (p) {
            b.parent = p;
          }
        }

        var children = [];
        b.children.forEach(function (c) {
          c = bl.findBy('id', c.id);

          if (c) {
            children.addObject(c);
          }
        });

        if (!b.parent) {
          console.log('SET CHILDREN', b, children);
        }

        b.children = children.sortBy('id');
        blocks.addObject(b);
      });
      return blocks;
    },
    initFlowData: function initFlowData() {
      var _this = this;

      var ruleOptions = JSON.parse(this.model.ruleOptions);
      var flow = this.get('selectedFlowTemplate');
      this.set('dataCollectors', [{
        id: 1,
        type: 'email',
        label: 'Email',
        text: 'Your email please?',
        icon: 'email'
      }, {
        id: 2,
        type: 'firstName',
        label: 'First Name',
        text: 'Your first name please?',
        icon: 'user-circle'
      }, {
        id: 3,
        type: 'lastName',
        label: 'Last Name',
        text: 'Your last name please?',
        icon: 'user-circle'
      }, {
        id: 4,
        type: 'number',
        label: 'Phone Number',
        text: 'Your number please?',
        icon: 'phone'
      }, {
        id: 5,
        type: 'mobile',
        label: 'Mobile Number',
        text: 'Your mobile please?',
        icon: 'mobile'
      }]);
      this.set('ruleOptions', ruleOptions);
      this.set('flowId', flow.id);
      this.set('blocks', this.deserializeFlow(flow.blocks || [])); //TODO add blocks loading

      this.set('rulesConditions', []);

      if (flow.rules) {
        (function () {
          var rules = JSON.parse(flow.rules.rules);

          _this.set('checkCondition', rules.checkCondition);

          rules.conditions.all.forEach(function (item) {
            Object.keys(item).forEach(function (key) {
              if (typeof item[key] === 'string') item[key] = item[key].replace('_', ' ');
            });
          });

          var _loop = function _loop(i) {
            var rulesConditions = {};
            var fieldType = ruleOptions.variables.filter(function (el) {
              return el.name === rules.conditions.all[i].name.replace(' ', '_');
            });

            if (!fieldType[0]) {
              return "continue";
            }

            ruleOptions.variable_type_operators[fieldType[0].field_type].forEach(function (val) {
              if (val.name === rules.conditions.all[i].operator.replace(' ', '_')) {
                rulesConditions.selectedCondition = val;
              }
            });
            rulesConditions.ruleOptions = ruleOptions.variable_type_operators[fieldType[0].field_type];
            ruleOptions.variables.forEach(function (val) {
              if (val.name === rules.conditions.all[i].name.replace(' ', '_')) {
                rulesConditions.selectedColumn = val;
              }
            });
            rulesConditions.selectedConditionValue = rules.conditions.all[i].value;

            _this.rulesConditions.pushObject(rulesConditions);
          };

          for (var i = 0; i < rules.conditions.all.length; i++) {
            var _ret = _loop(i);

            if (_ret === "continue") continue;
          }
        })();
      }

      this.set('ruleStruct', {
        conditions: {
          all: []
        },
        actions: []
      });
      this.set('checkConditions', [{
        id: 1,
        title: this.intl.t('matches_all_conditions')
      }, {
        id: 2,
        title: this.intl.t('matches_any_conditions')
      }]);
      this.set('checkCondition', {
        id: 1,
        title: this.intl.t('matches_all_conditions')
      });
      this.set('rule', {});
      this.set('flowComponents', [{
        name: 'Message',
        type: 'message',
        icon: 'message-circle',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Options (Single select)',
        type: 'single',
        icon: 'options-single',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Options (Cards)',
        type: 'slider',
        icon: 'slider',
        data: null,
        blockType: 'SLIDER'
      }, {
        name: 'Data Collector (form)',
        type: 'data',
        icon: 'data-collector',
        data: null,
        blockType: 'FORM'
      }, {
        name: 'Action',
        type: 'action',
        icon: 'play',
        data: null,
        blockType: 'TEXT'
      }]);
      this.set('commentModules', [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }]);
      this.set('chatModules', [{
        id: 'rooms',
        label: 'Teams'
      }, {
        id: 'users',
        label: 'Direct Messages'
      }]);
      this.set('tagModules', [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }]);
      this.set('optionsEditor', {
        actions: ['bold', 'italic', 'underline', 'strikethrough', 'heading1', 'heading2', 'olist', 'ulist']
      });
      this.set('actionsFlow', [{
        type: 'assign',
        label: 'Assign to'
      }, {
        type: 'tag',
        label: 'Tag the visitor'
      }, {
        type: 'contacts',
        label: 'Add visitor to Contacts'
      }, {
        type: 'close',
        label: 'Close the conversation'
      }, {
        type: 'create_ticket',
        label: 'Create ticket'
      }]);
    },
    selectedFlowTab: 'settings',
    openConfigureComponent: function openConfigureComponent(id) {
      var _this2 = this;

      var blocks = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var newBlocks = blocks || this.get('blocks');
      var prevActiveBlock = newBlocks.findBy('id', this.get('selectedComponent.id'));
      var activeBlock = newBlocks.findBy('id', id);
      this.set('cursor', {});
      (0, _jquery.default)('.item-flow-wrap').removeClass('active');
      (0, _jquery.default)('.add-component-flow').removeClass('active');
      prevActiveBlock && Ember.set(prevActiveBlock, 'active', false);
      this.set('selectedFlowTab', 'configure');
      this.set('isShowListComponents', false);
      this.set('isActiveStartBlockFlow', false);

      if (activeBlock) {
        if (activeBlock.blockType === 'FORM') {
          (function () {
            var extraData = JSON.parse(activeBlock.extraData);
            Ember.set(activeBlock, 'dataCollectorsSelect', extraData.dataCollectorsSelect);

            var dataCollectors = _toConsumableArray(_this2.get('dataCollectors'));

            var parent = activeBlock;

            while (parent && dataCollectors.length) {
              var _parent$parent;

              if (parent.blockType === 'FORM') {
                var _extraData = JSON.parse(parent.extraData);

                _extraData.dataCollectorsSelect.forEach(function (d) {
                  dataCollectors.removeObject(dataCollectors.findBy('id', d.id));
                });
              }

              parent = newBlocks.findBy('id', (_parent$parent = parent.parent) === null || _parent$parent === void 0 ? void 0 : _parent$parent.id);
            } // this.checkChildren(activeBlock, newBlocks, dataCollectors);


            _this2.set('dataCollectorsSelect', dataCollectors);
          })();
        } else if (activeBlock.name === 'Action') {
          var ruleOptions = this.get('ruleOptions');

          if (typeof activeBlock.actions === 'string') {
            (function () {
              var extraData = JSON.parse(activeBlock.actions);
              Ember.set(activeBlock, 'actions', extraData.actions);
              Ember.set(activeBlock, 'alwaysBtn', extraData.alwaysBtn);
              Ember.set(activeBlock, 'checkCondition', extraData.checkCondition);
              Ember.set(activeBlock, 'actionsFlow', extraData.actionsFlow);
              Ember.set(activeBlock, 'rules', []);

              if (extraData.rules.length) {
                extraData.rules.forEach(function (item) {
                  Object.keys(item).forEach(function (key) {
                    if (typeof item[key] === 'string') item[key] = item[key].replace('_', ' ');
                  });
                });

                var _loop2 = function _loop2(i) {
                  var rulesConditions = {};
                  var fieldType = ruleOptions.variables.filter(function (el) {
                    return el.name === extraData.rules[i].name.replace(' ', '_');
                  });
                  fieldType.length && ruleOptions.variable_type_operators[fieldType[0].field_type].forEach(function (val) {
                    if (val.name === extraData.rules[i].operator.replace(' ', '_')) {
                      rulesConditions.selectedCondition = val;
                    }
                  });
                  rulesConditions.ruleOptions = ruleOptions.variable_type_operators[fieldType[0].field_type];
                  ruleOptions.variables.forEach(function (val) {
                    if (val.name === extraData.rules[i].name.replace(' ', '_')) {
                      rulesConditions.selectedColumn = val;
                    }
                  });
                  rulesConditions.selectedConditionValue = extraData.rules[i].value;
                  activeBlock.rules.pushObject(rulesConditions);
                };

                for (var i = 0; i < extraData.rules.length; i++) {
                  _loop2(i);
                }
              }
            })();
          }
        } else if (activeBlock.blockType === 'BUTTON') {
          var parentFlow = newBlocks.findBy('id', activeBlock.parent.id);

          if (parentFlow) {
            activeBlock = parentFlow;
          }
        } else if (activeBlock.blockType === 'SLIDER') {
          Ember.set(activeBlock, 'children', activeBlock.children.filter(function (child) {
            return child.blockType === 'BUTTON';
          }));
        } else if (activeBlock.blockType === 'COMMENT') {
          this.changeParamModule(activeBlock);
          this.resetCommentModule(activeBlock);
        } else if (activeBlock.blockType === 'TAG') {
          this.changeParamModuleTags(activeBlock);
          this.resetCommentModule(activeBlock);
        }

        Ember.set(activeBlock, 'active', true);
        this.set('selectedComponent', activeBlock);
      } else {
        this.set('selectedComponent', null);
        this.set('isActiveStartBlockFlow', true);
        this.triggerOpenStartConfigure();
      }

      this.set('isLoadingComponent', false);
      setTimeout(function () {
        var activeElement = document.querySelector('.item-flow.active');

        if (document.getElementById('editor')) {
          document.getElementById('editor').innerHTML = activeBlock.mainTitle;
        }

        _this2.set('reRender', true); // activeElement && activeElement.scrollIntoView({ behavior: 'smooth' });

      }, 500);
    },
    triggerOpenStartConfigure: function triggerOpenStartConfigure() {
      (0, _jquery.default)('.item-flow').removeClass('active');
      (0, _jquery.default)('.add-component-flow').removeClass('active');
      (0, _jquery.default)('.item-flow-wrap').addClass('active');
      this.set('isShowComponent', false);
      this.set('isShowListComponents', false);
      this.set('isActiveStartBlockFlow', true);
      this.set('selectedFlowTab', 'configure');
    },
    actions: {
      onSelectTemplate: function onSelectTemplate(flow) {
        this.set('selectedFlowTemplate', flow);
        this.initFlowData();
        this.set('isShowTemplateFlow', false);
      },
      onCreateTemplate: function onCreateTemplate() {
        var _this3 = this;

        var hash = {
          title: "Template".concat(String(Math.random()).slice(2)),
          description: '',
          idleTime: 20,
          timeOut: 20,
          warningMessage: 'Hi, we have not heard anything from you for the past 20 seconds, ' + 'please type something to remain active in chat',
          timeOutMessage: 'This chat session has been terminated due to time-out, we are still here with you if you need anything',
          isActive: false,
          auto: true,
          companyProperty: this.get('sessionAccount.loggeduser.staffprofile.estates')[0].id,
          extraData: JSON.stringify({
            isTemplate: true
          })
        };
        this.set('awaitCreateFlow', true);
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash, function (_ref) {
          var flow = _ref.flow;

          _this3.set('awaitCreateFlow', false);

          _this3.paperToaster.show(_this3.intl.t('flow_created'));

          _this3.transitionToRoute('flows.tmpl', {
            queryParams: {
              id: flow.id
            }
          });
        });
      },
      hideTemplate: function hideTemplate() {
        this.set('isShowTemplateFlow', false);
        this.set('selectedFlowTemplate', null);
      },
      useTemplate: function useTemplate() {
        this.set('isShowTemplateFlow', true);
      },
      backToTemplates: function backToTemplates() {
        this.set('isShowTemplateFlow', false);
      },
      createNewFlow: function createNewFlow() {
        var _this4 = this;

        var selectedFlow = this.get('selectedFlowTemplate'); // this.set('isLoader', true);

        this.ajax.query(_mutation.default.DUPLICATE_FLOW, 'duplicateFlow', {
          flowId: selectedFlow.id
        }).then(function (_ref2) {
          var flow = _ref2.flow;

          if (flow) {
            var hash = {
              flowId: flow.id,
              title: "Created From ".concat(selectedFlow.templateTitle),
              description: selectedFlow.templateDescription,
              isDefault: false
            };

            _this4.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash, function (_ref3) {
              var flow = _ref3.flow;

              _this4.set('selectedFlowTemplate', null);

              _this4.set('isShowTemplates', false);

              _this4.set('isShowTemplateFlow', false);

              _this4.transitionToRoute('flows.edit', {
                queryParams: {
                  id: flow.id
                }
              });
            });
          } else {
            _this4.paperToaster.show('Hmmm');
          }
        });
      },
      openListComponents: function openListComponents() {
        var component = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var event = arguments.length > 1 ? arguments[1] : undefined;
        event.stopPropagation();

        if (component) {
          this.set('selectedComponent', component);
        } else {
          var blocks = this.get('blocks');
          this.set('selectedComponent', {
            children: blocks[0] ? [{
              id: blocks[0].id
            }] : [],
            parent: null
          });
        }

        this.set('selectedFlowTab', 'configure');
        this.set('isShowListComponents', true);
        this.set('isActiveStartBlockFlow', false);
        this.set('isShowComponent', false);
        (0, _jquery.default)('.item-flow, .item-flow-wrap').removeClass('active');
        (0, _jquery.default)('.add-component-flow').removeClass('active');
        (0, _jquery.default)(event.target).closest('.add-component-flow').addClass('active');
      },
      openStartConfigure: function openStartConfigure() {
        this.triggerOpenStartConfigure();
      },
      openConfigure: function openConfigure(component) {
        this.set('isShowComponent', true);
        this.openConfigureComponent(component.id);

        if (document.querySelector('.txt-input')) {
          setTimeout(function () {
            document.querySelector('.txt-input').innerHTML = component.mainTitle;
          }, 0);
        }
      },
      onSelectField: function onSelectField(field) {
        console.log('ON SELECT FIELD', field); // const dataCollectorsSelect = this.get('selectedComponent.dataCollectorsSelect');
        // dataCollectorsSelect.pushObject(field);
        // this.get('dataCollectorsSelect').removeObject(field);
        // const hash = {
        //     flowId: this.get('flowId'),
        //     blockId: this.get('selectedComponent.id'),
        //     extraData: JSON.stringify({dataCollectorsSelect}),
        // };
        //
        // this.ajax.query(Mutation.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(() => {
        //     this.updateFlow(this.get('selectedComponent.id'));
        // });
      },
      showTemplates: function showTemplates() {
        this.set('isShowTemplates', true);
        this.set('isShowTemplateFlow', false);
      },
      showFlows: function showFlows() {
        this.set('isShowTemplates', false);
        this.set('isShowTemplateFlow', false);
      },
      sortFlow: function sortFlow(fieldName) {
        var sortedFlows;
        var flows = this.get('flowsOnPage');
        var isReverse = this.get('isReverse');

        if (fieldName === "Assistant Name") {
          if (isReverse.title) {
            sortedFlows = flows.sortBy("title").reverse();
            this.toggleProperty("isReverse.title");
          } else {
            sortedFlows = flows.sortBy("title");
            this.toggleProperty("isReverse.title");
          }
        }

        if (fieldName === "Sent") {
          if (isReverse.triggered) {
            sortedFlows = flows.sortBy("triggered").reverse();
            this.toggleProperty("isReverse.triggered");
          } else {
            sortedFlows = flows.sortBy("triggered");
            this.toggleProperty("isReverse.triggered");
          }
        }

        if (fieldName === "Engaged") {
          if (isReverse.engaged) {
            sortedFlows = flows.sortBy("engaged").reverse();
            this.toggleProperty("isReverse.engaged");
          } else {
            sortedFlows = flows.sortBy("engaged");
            this.toggleProperty("isReverse.engaged");
          }
        }

        if (fieldName === "Completed") {
          if (isReverse.completed) {
            sortedFlows = flows.sortBy("completed").reverse();
            this.toggleProperty("isReverse.completed");
          } else {
            sortedFlows = flows.sortBy("completed");
            this.toggleProperty("isReverse.completed");
          }
        }

        if (fieldName === "Modified On") {
          if (isReverse.updatedDate) {
            sortedFlows = flows.sortBy("updatedDate").reverse();
            this.toggleProperty("isReverse.updatedDate");
          } else {
            sortedFlows = flows.sortBy("updatedDate");
            this.toggleProperty("isReverse.updatedDate");
          }
        }

        this.set('flowsOnPage', sortedFlows);
      },
      createFlow: function createFlow() {
        var _this5 = this;

        var hash = {
          title: "Flow".concat(String(Math.random()).slice(2)),
          description: '',
          idleTime: 20,
          timeOut: 20,
          warningMessage: 'Hi, we have not heard anything from you for the past 20 seconds, ' + 'please type something to remain active in chat',
          timeOutMessage: 'This chat session has been terminated due to time-out, we are still here with you if you need anything',
          isActive: false,
          auto: true,
          type: 1,
          companyProperty: this.get('sessionAccount.loggeduser.staffprofile.estates')[0].id
        };
        this.set('awaitCreateFlow', true);
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash, function (_ref4) {
          var flow = _ref4.flow;

          _this5.set('awaitCreateFlow', false);

          _this5.paperToaster.show(_this5.intl.t('flow_created'));

          _this5.transitionToRoute('flows.edit', {
            queryParams: {
              id: flow.id
            }
          });
        });
      },
      showDeleteFlowDialog: function showDeleteFlowDialog(flow) {
        this.set('selectedFlow', flow);
        this.set('openDeleteFlowDialog', !this.get('openDeleteFlowDialog'));
      },
      showDuplicateFlowDialog: function showDuplicateFlowDialog(flow) {
        if (flow) this.set('selectedFlow', flow);
        this.set('openDuplicateFlowDialog', true);
      },
      deleteFlow: function deleteFlow() {
        var _this6 = this;

        var selectedFlow = this.get('selectedFlow');
        this.set('isLoader', true);
        this.ajax.query(_mutation.default.DELETE_CHATBOT, 'deleteChatbot', {
          botId: selectedFlow.id
        }).then(function () {
          console.log('DEL CHATBOT', selectedFlow.id);

          _this6.set('openDeleteFlowDialog', false);

          _this6.paperToaster.show(_this6.intl.t('delete_flow', {
            name: selectedFlow.title
          }));
        }).finally(function () {
          _this6.queryFlows();
        });
      },
      copyFlow: function copyFlow() {
        var _this7 = this;

        var selectedFlow = this.get('selectedFlow');
        this.set('isLoader', true);
        this.ajax.query(_mutation.default.DUPLICATE_FLOW, 'duplicateFlow', {
          flowId: selectedFlow.id
        }).then(function () {
          _this7.set('openDuplicateFlowDialog', false);

          _this7.paperToaster.show(_this7.intl.t('duplicate_flow', {
            name: selectedFlow.title
          }));
        }).finally(function () {
          _this7.queryFlows();
        });
      },
      statusChangedItemFlow: function statusChangedItemFlow(flow) {
        var _this8 = this;

        this.set('selectedFlow', flow);

        if (flow.isActive) {
          Ember.set(flow, 'isActive', false);
          var hash = {
            flowId: flow.id,
            isActive: false
          };
          this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash).then(function () {
            _this8.paperToaster.show(_this8.intl.t('deactivate_flow', {
              name: flow.title
            }));
          }).finally(function () {
            _this8.queryFlows();
          });
        } else {
          this.set('openActivateFlowDialog', true);
        }
      },
      activateFlow: function activateFlow() {
        var _this9 = this;

        var flow = this.get('selectedFlow');

        if (!flow.isDisabled) {
          Ember.set(flow, 'isActive', true);
          var hash = {
            flowId: flow.id,
            isActive: true
          };
          this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash).then(function () {
            _this9.paperToaster.show(_this9.intl.t('activate_flow', {
              name: flow.title
            }));
          }).finally(function () {
            _this9.queryFlows();
          });
        }
      }
    },
    queryFlows: function queryFlows() {
      var _this10 = this;

      this.set('isLoader', true);
      var DUMMY_CHATBOTS = [{
        "id": "1",
        "title": "PROACTIVE",
        "auto": false,
        "type": 0,
        "engaged": 0,
        "triggered": 0,
        "complited": 0,
        "description": "PROACTIVE",
        "isActive": true,
        "isSystem": false,
        "updatedDate": "2024-01-16T06:10:50.852828",
        "extraData": null,
        "checked": false
      }, {
        "id": "2",
        "title": "SMART",
        "auto": false,
        "type": 0,
        "engaged": 0,
        "triggered": 0,
        "complited": 0,
        "description": "SMART",
        "isActive": true,
        "isSystem": false,
        "updatedDate": "2024-01-16T06:10:50.865378",
        "extraData": null,
        "checked": false
      }, {
        "id": "3",
        "title": "Default Template",
        "auto": true,
        "type": 1,
        "engaged": 0,
        "triggered": 0,
        "complited": 0,
        "description": "Will be run if all agents are offline.",
        "isActive": true,
        "isSystem": true,
        "updatedDate": "2024-01-16T06:10:50.872246",
        "extraData": "{\"isDisabled\":0}",
        "isDisabled": false,
        "isTemplate": false,
        "checked": false
      }, {
        "id": "11",
        "title": "Template21886442677794538",
        "auto": true,
        "type": 1,
        "engaged": 0,
        "triggered": 0,
        "complited": 0,
        "description": "",
        "isActive": false,
        "isSystem": false,
        "updatedDate": "2024-02-01T22:30:57.384328",
        "extraData": "{\"isDisabled\":true,\"isTemplate\":true}",
        "isDisabled": true,
        "isTemplate": true
      }, {
        "id": "15",
        "title": "PROACTIVE",
        "auto": false,
        "type": 0,
        "engaged": 0,
        "triggered": 0,
        "complited": 0,
        "description": "PROACTIVE",
        "isActive": false,
        "isSystem": false,
        "updatedDate": "2024-02-06T02:49:01.292410",
        "extraData": null,
        "checked": false
      }, {
        "id": "16",
        "title": "SMART",
        "auto": false,
        "type": 0,
        "engaged": 0,
        "triggered": 0,
        "complited": 0,
        "description": "SMART",
        "isActive": true,
        "isSystem": false,
        "updatedDate": "2024-02-06T02:49:01.319795",
        "extraData": null,
        "checked": false
      }, {
        "id": "17",
        "title": "Default Template",
        "auto": true,
        "type": 1,
        "engaged": 0,
        "triggered": 0,
        "complited": 0,
        "description": "Will be run if all agents are offline.",
        "isActive": true,
        "isSystem": true,
        "updatedDate": "2024-02-06T02:49:01.333814",
        "extraData": "{\"isDisabled\": 0}",
        "isDisabled": false,
        "isTemplate": false,
        "checked": false
      }, {
        "id": "37",
        "title": "Flow_test",
        "auto": true,
        "type": 1,
        "engaged": 0,
        "triggered": 0,
        "complited": 0,
        "description": "",
        "isActive": false,
        "isSystem": false,
        "updatedDate": "2024-05-23T05:18:46.208542",
        "extraData": "{\"isDisabled\":true}",
        "isDisabled": true,
        "isTemplate": false,
        "checked": false
      }, {
        "id": "40",
        "title": "Flow3258179413866671",
        "auto": true,
        "type": 1,
        "engaged": 0,
        "triggered": 0,
        "complited": 0,
        "description": "",
        "isActive": false,
        "isSystem": false,
        "updatedDate": "2024-09-27T06:37:08.024749",
        "extraData": null,
        "checked": false
      }, {
        "id": "41",
        "title": "Flow3258179413866671_copy",
        "auto": true,
        "type": 1,
        "engaged": 0,
        "triggered": 0,
        "complited": 0,
        "description": "",
        "isActive": false,
        "isSystem": false,
        "updatedDate": "2024-10-10T08:01:27.319958",
        "extraData": null,
        "checked": false
      }, {
        "id": "42",
        "title": "Flow62331042960__68845",
        "auto": true,
        "type": 1,
        "engaged": 0,
        "triggered": 0,
        "complited": 0,
        "description": "",
        "isActive": false,
        "isSystem": false,
        "updatedDate": "2024-10-15T03:46:56.258753",
        "extraData": "{\"isDisabled\":0}",
        "isDisabled": false,
        "isTemplate": false,
        "checked": false
      }];
      return this.ajax.query(_query.default.CHATBOTS).then(function (data) {
        console.log('ALL FLOWS', data);

        if (data.errors) {
          _this10.set('allFlows', []);

          _this10.set('flowTemplates', []);
        } else {
          var allFlows = data;
          data.forEach(function (flow) {
            if (flow.extraData) {
              var extraData = JSON.parse(flow.extraData);
              flow.isDisabled = Boolean(extraData.isDisabled);
              flow.isTemplate = Boolean(extraData.isTemplate);
            }
          });
          var currentFlows = allFlows.filter(function (f) {
            return !f.isTemplate;
          });

          _this10.set('allFlows', currentFlows);

          _this10.set('flowTemplates', allFlows.filter(function (f) {
            return f.isTemplate;
          }));

          _this10.set('pageNum', _this10.get('sliceData').getPageNum());
        }
      }).finally(function () {
        _this10.set('isLoader', false);
      });
    }
  });

  _exports.default = _default;
});
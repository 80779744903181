define("inboxfront/components/assistants/flows/trigger-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    chatbots: Ember.inject.service(),
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('triggerLimitations', this.chatbots.triggerLimitations);
      this.set('pageScrollOptions', ['10%', '25%', '50%', '75%', '100%']);
      this.set('delay_options', ['seconds', 'minutes', 'hours']);
    },
    actions: {
      onUpdateTrigger: function onUpdateTrigger(name, value) {
        Ember.set(this.get('selectedTrigger'), name, value);
        this.onUpdate();
      }
    }
  });

  _exports.default = _default;
});
define("inboxfront/utils/commands-queue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CommandsQueue = void 0;
  var CommandsQueue = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.undoCmd = null;
      this.redoCmd = null;
    },
    undo: function undo() {
      var cmd = this.get('undoCmd');

      if (cmd) {
        // cmd.undo();
        console.log('UNDO', cmd, this.get('blocks'));

        if (cmd.action === 'remove') {
          var block = cmd.block;
          block.getInConnect().connections.forEach(function (c) {
            if (c.outConnect.parentBlock.removed) {
              return;
            }

            c.removed = false; // c.outConnect.removeConnection(c);
          });
          block.getOutConnect().connections.forEach(function (c) {
            if (c.inConnect.parentBlock.removed) {
              return;
            }

            c.removed = false; // c.inConnect?.removeConnection(c);
          }); // set(block, 'block.extraData.removed', false);
          // block.removed = false;
          // this.set('blocks', [...this.get('blocks')]);
          // this.render();
        }

        this.set('undoCmd', cmd.prev);
        this.set('redoCmd', cmd);
      } // if (this.lastCommand >= 0) {
      //     const command = this.commands[this.lastCommand];
      //     this.lastCommand = this.lastCommand - 1;
      //     console.log('UNDO', command);
      // }

    },
    redo: function redo() {
      var cmd = this.get('redoCmd');

      if (cmd) {
        // cmd.redo();
        console.log('REDO', cmd, this.get('blocks'));

        if (cmd.action === 'remove') {
          var block = cmd.block;
          block.getInConnect().connections.forEach(function (c) {
            c.removed = true; // c.outConnect.removeConnection(c);
          });
          block.getOutConnect().connections.forEach(function (c) {
            c.removed = true; // c.inConnect?.removeConnection(c);
          }); // set(block, 'block.extraData.removed', true);
          // set(block, 'removed', true);
          // this.set('blocks', [...this.get('blocks')]);
          // this.render();
        }

        this.set('undoCmd', cmd);
        this.set('redoCmd', cmd.next);
      } // if (this.lastCommand >= 0 && this.lastCommand < (this.commands.length - 2)) {
      //     const command = this.commands[this.lastCommand+1];
      //     console.log('REDO', command);
      // }

    },
    addCmd: function addCmd(newCmd) {
      var undoCmd = this.get('undoCmd');
      Ember.set(newCmd, 'prev', undoCmd);

      if (undoCmd) {
        Ember.set(undoCmd, 'next', newCmd);
      }

      console.log('ADD COMMAND', newCmd);
      this.set('redoCmd', null);
      this.set('undoCmd', newCmd);
    },
    clear: function clear() {}
  });
  _exports.CommandsQueue = CommandsQueue;
});
define("inboxfront/routes/flows/edit", ["exports", "inboxfront/routes/common", "inboxfront/helpers/countries-list", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _common, _countriesList, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var WORKFLOW = 2;

  var _default = _common.default.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    emoji: Ember.inject.service(),
    chatbots: Ember.inject.service(),
    users: Ember.inject.service(),
    tasks: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.id == null) {
        this.transitionTo('flows');
      } else {
        // const test = this.ajax.testQuery(Query.TEST_QUERY);
        var chatbot = this.chatbots.getById(params.id);
        var ruleOptions = this.ajax.query(_query.default.OPTIONS);
        var rooms = this.ajax.query(_query.default.ROOMS_FLOW);
        this.emoji.initEmojis();
        return Ember.RSVP.all([chatbot, this.users.getPropertyUsers(), ruleOptions, rooms, this.tasks.getIssueTypes()]).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 5),
              chatbot = _ref2[0],
              propertyUsers = _ref2[1],
              ruleOptions = _ref2[2],
              rooms = _ref2[3],
              issueTypes = _ref2[4];

          if (!chatbot) {
            _this.transitionTo('flows');
          } else {
            return {
              chatbot: chatbot,
              propertyUsers: propertyUsers,
              ruleOptions: ruleOptions,
              issueTypes: issueTypes,
              rooms: rooms
            };
          }
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('widget', this.users.widgetSettings());
      var chatbot = model.chatbot,
          propertyUsers = model.propertyUsers,
          ruleOptions = model.ruleOptions;
      controller.set('ruleOptions', JSON.parse(ruleOptions));
      controller.set('propertyUsers', propertyUsers); // controller.set('dataCollectors', [
      //     {id: 1, type: 'email', label: 'Email', text: 'Your email please?', icon: 'email'},
      //     {id: 2, type: 'firstName', label: 'First Name', text: 'Your first name please?', icon: 'user-circle'},
      //     {id: 3, type: 'lastName', label: 'Last Name', text: 'Your last name please?', icon: 'user-circle'},
      //     {id: 4, type: 'number', label: 'Phone Number', text: 'Your number please?', icon: 'phone'},
      //     {id: 5, type: 'mobile', label: 'Mobile Number', text: 'Your mobile please?', icon: 'mobile'},
      // ]);
      // controller.updateDataCollectorsSelect(flow.blocks);

      controller.set('flow', chatbot);
      controller.set('flowId', chatbot.id);
      controller.set('flowName', chatbot.title);
      controller.set('blocks', chatbot.blocks);
      controller.set('triggers', chatbot.triggers);
      controller.set('isActiveFlow', chatbot.isActive);
      controller.set('subscribeResponses', ['Emoji: Thumbs Up/Thumbs Down', 'Text: Sure. / No, thanks.']);
      controller.set('rulesConditions', []);
      controller.set('ruleStruct', {
        conditions: {
          all: []
        },
        actions: []
      });
      controller.set('checkConditions', [{
        id: 1,
        title: this.intl.t('matches_all_conditions')
      }, {
        id: 2,
        title: this.intl.t('matches_any_conditions')
      }]);
      controller.set('checkCondition', {
        id: 1,
        title: this.intl.t('matches_all_conditions')
      });
      controller.set('rule', {});
      controller.set('commentModules', [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }]);
      controller.set('chatModules', [{
        id: 'rooms',
        label: 'Teams'
      }, {
        id: 'users',
        label: 'Direct Messages'
      }]);
      controller.set('tagModules', [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }]);
      controller.set('optionsEditor', {
        actions: ['bold', 'italic', 'underline', 'strikethrough', 'heading1', 'heading2', 'olist', 'ulist']
      }); // controller.set('actionsFlow', [
      //     {type: 'assign', label: 'Assign to'},
      //     {type: 'tag', label: 'Tag the visitor'},
      //     {type: 'contacts', label: 'Add visitor to Contacts'},
      //     {type: 'close', label: 'Close the conversation'},
      //     {type: 'create_ticket', label: 'Create ticket'},
      // ]);

      controller.set('triggerOptions', {
        variables: ['Ticket/Task', 'Conversation']
      });
      controller.set('someText', '{{..}}');
      controller.set('subjVariables', ['Ticket Subject', 'Order ID', 'Ticket Issue Type', 'Ticket Source']);
      controller.set('bodyVariables', ['Customer Name', 'Customer Email', 'Issue Type', 'Source', 'Assignee']);
      controller.set('toVariables', ['Customer Email']);
      controller.set('periodTimes', ['days', 'hours', 'minutes']);
      controller.set('conditionRules', {
        'Ticket/Task': ['Status', 'Priority', 'Issue Type', 'Source', 'Department', 'Assignee', 'Attachment'],
        'Conversation': ['Channel/source', 'Assignee', 'Country', 'Refund', 'Cancel', 'Profile qualification']
      });
      controller.set('conditionOperators', ['AND', 'OR']);
      /**/

      controller.set('isShowTriggers', true);
      controller.set('isActiveStartBlockFlow', true);
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.controller.resetView && this.controller.resetView();

        this._super(transition);
      }
    }
  });

  _exports.default = _default;
});
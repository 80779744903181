define("inboxfront/components/assistants/chatbot-preview", ["exports", "inboxfront/gql/query"], function (_exports, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    chatbots: Ember.inject.service(),
    isLoading: false,
    nextBlockButton: false,
    currentIndex: 0,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.blockList = [];
      this.set('isLoading', true);
      setTimeout(function () {
        _this.set('isLoading', false);
      }, 2000);
      this.chatbots.getById(this.get('flowId')).then(function (chatbot) {
        _this.set('blocks', chatbot.blocks);

        console.log('SHOW CHATBOT PREVIEW', chatbot.firstBlock, _this.get('blocks')); //NEW chatbot.firstBlock && this.runFlow(chatbot.firstBlock);
        // console.log('WIDGET', this.get('widget'));
      });
    },
    actions: {
      reRun: function reRun() {
        var _this2 = this;

        this.set('blockList', []);
        this.set('isLoading', true);
        setTimeout(function () {
          _this2.set('isLoading', false);
        }, 2000);
      },
      sendChat: function sendChat(button, block, index) {
        console.log('SEND CHAT', button, block);

        if (button.mainTitle) {
          if (block.extraData.connections[index]) {
            Ember.set(block, 'hidden', true);
            this.get('blockList').pushObject({
              mainTitle: button.mainTitle,
              position: 'right'
            });
            var nextBlock = this.get('blocks').findBy('id', block.extraData.connections[index]);
            this.set('nextBlock', nextBlock); // this.set('nextBlockButton', true);
          } // if (button.children.length) {
          //     set(block, 'hidden', true);
          //     this.get('blockList').pushObject({mainTitle: button.mainTitle, position: 'right'});
          //     const nextBlock = this.get('blocks').findBy('id', button.children[0].id);
          //     this.set('nextBlock', nextBlock);
          //     this.set('nextBlockButton', true);
          // } else {
          //     return;
          // }

        } else {
          this.get('blockList').pushObject({
            mainTitle: this.get('messageInput'),
            position: 'right'
          });
          this.set('messageInput', '');
        }

        this.renderMessage();
      }
    },
    runFlow: function runFlow(firstBlock) {
      var _this3 = this;

      // if (!firstBlock) {
      // 	return;
      // }
      this.set('isLoading', true); // undefined.map(() => {});
      // return;

      console.log('RUN CB', firstBlock, this.get('blocks'));

      if (firstBlock.extraData.connections[0]) {
        this.set('nextBlock', this.get('blocks').findBy('id', firstBlock.extraData.connections[0]));
      } // if (firstBlock.blockType === 'FORM') {
      // 	this.set('nextBlock', firstBlock);
      // } else if (firstBlock.blockType === 'SLIDER') {
      // 	const nextBlock = firstBlock.children[0] ? firstBlock.children.filter(child => child.blockType !== 'BUTTON')[0] : {};
      // 	this.set('nextBlock', nextBlock);
      // } else if (firstBlock.extraData?.type === 'random') {
      // 	console.log('RAND', firstBlock.children, firstBlock);
      // 	this.set('nextBlock', firstBlock && firstBlock.children[0] && firstBlock.children[0]);
      // } else {
      // 	this.set('nextBlock', firstBlock && firstBlock.children[0] && firstBlock.children[0]);
      // }
      // console.log('CURRENT BLOCK', firstBlock, this.get('nextBlock'));


      setTimeout(function () {
        _this3.set('isLoading', false);

        if (firstBlock) {
          var nextBlock = _objectSpread(_objectSpread({}, firstBlock), {}, {
            position: 'left'
          });

          _this3.get('blockList').pushObject(nextBlock); // if (firstBlock.blockType === 'FORM') {
          // 	this.renderMessage();
          // }

        }
      }, 200);
    },
    renderMessage: function renderMessage() {
      var _this4 = this;

      var listWrapperLastChild = document.querySelector('.list-wrapper').lastElementChild;
      console.log('RENDER MSG', this.get('nextBlock'));

      if (!this.get('nextBlockButton')) {
        var nextBlockId = this.get('nextBlock.id');

        if (nextBlockId) {
          if (this.get('nextBlock.blockType') === 'FORM') {
            this.renderFormMessage();
          } else if (this.get('nextBlock.blockType') === 'SLIDER') {
            this.set('nextBlock', this.get('nextBlock.children').filter(function (child) {
              return child.blockType !== 'BUTTON';
            })[0]);
          } else if (this.get('nextBlock.name') === 'Randomize') {
            var connections = this.get('nextBlock.extraData.connections');

            if (connections.length) {
              var nextId = connections[Math.floor(Math.random() * connections.length)];
              this.set('nextBlock', this.get('blocks').findBy('id', nextId));
              console.log('REND NEXT', this.get('nextBlock'));
            } else {
              return;
            } // this.set('nextBlock', this.get('nextBlock.children').filter(child => child.blockType !== 'BUTTON')[0]);

          } else {
            this.set('nextBlock', this.get('blocks').findBy('id', nextBlockId));
            console.log('REND');
          }
        } else {
          return;
        } // this.get('nextBlock.blockType') !== 'FORM' && this.set('nextBlock', this.get('nextBlock'));

      } else {
        /*if (this.get('nextBlock.blockType') === 'FORM') {
            this.renderFormMessage();
        }*/
        this.set('nextBlockButton', false);
      }

      this.set('isLoading', true);
      setTimeout(function () {
        _this4.set('isLoading', false);

        _this4.get('nextBlock') && _this4.get('blockList').pushObject(_this4.get('nextBlock'));

        if (_this4.get('nextBlock.blockType') === 'FORM' && _this4.get('currentIndex') === 0) {
          _this4.renderMessage();
        }

        listWrapperLastChild.scrollIntoView({
          behavior: 'smooth'
        });
      }, 500);
    },
    renderFormMessage: function renderFormMessage() {
      var nextBlock = this.get('nextBlock');

      var currentBlock = _objectSpread({}, nextBlock);

      var index = this.get('currentIndex');
      var extraData = JSON.parse(nextBlock.extraData);
      currentBlock.position = 'left';
      currentBlock.mainTitle = extraData.dataCollectorsSelect[index] && extraData.dataCollectorsSelect[index].text;
      this.set('currentIndex', index + 1);

      if (!currentBlock.mainTitle) {
        currentBlock = nextBlock.children[0] && this.get('blocks').findBy('id', nextBlock.children[0].id);
        this.set('currentIndex', 0);
      }

      this.set('nextBlock', currentBlock);
    }
  });

  _exports.default = _default;
});
define("inboxfront/utils/new-canvas-editor", ["exports", "inboxfront/utils/new-canvas-objects", "inboxfront/utils/new-canvas-editor-states"], function (_exports, _newCanvasObjects, states) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Editor = void 0;
  var ZOOM_STEP = 10;
  var MINIMUM_ZOOM = 30;
  var MAXIMUM_ZOOM = 200;
  var SCROLL_STEP = 50;
  var CANVAS_LEFT = 70; //HARDCODED, should be calculated, depends on canvas position in layout

  var CANVAS_TOP = 122; //HARDCODED, should be calculated, depends on canvas position in layout

  var Editor = Ember.Object.extend({
    init: function init(canvas) {
      var _this = this;

      this._super.apply(this, arguments);

      this._awaitState = new states.AwaitState(this);
      this._beginDragCanvasState = new states.BeginDragCanvasState(this);
      this._beginDragState = new states.BeginDragState(this);
      this._awaitEndConnectionState = new states.AwaitEndConnectionState(this);
      this._canvas = canvas;

      canvas.onmousedown = function (e) {
        _this._state.mousedown(_this._getCoords(e), e);
      };

      canvas.onmousemove = function (e) {
        var p = _this._getCoords(e);

        _this.setCursor(_this.getCurrentStateCursor(p));

        _this._state.mousemove(p, e);

        _this._renderCrosshair(_this._ctx, p, e);
      };

      canvas.onmouseup = function (e) {
        _this._state.mouseup(_this._getCoords(e), e);
      };

      canvas.onmouseleave = function (e) {
        _this.setState(_this._awaitState);
      };

      this._ctx = canvas.getContext('2d');
      this.clear();
    },
    getContext: function getContext() {
      return this._ctx;
    },
    _getCoords: function _getCoords(event) {
      var eventPoint = this._getEventPoint(event);

      var x = Math.round((eventPoint.x - this._axesPoint.x) * (100.0 / this._scale));
      var y = Math.round((eventPoint.y - this._axesPoint.y) * (100.0 / this._scale));
      return (0, _newCanvasObjects.Point)(x, y);
    },
    _getEventPoint: function _getEventPoint(e) {
      return (0, _newCanvasObjects.Point)(e.pageX - CANVAS_LEFT, e.pageY - CANVAS_TOP); //TODO rework, get canvas top-left position
    },
    getObjectFromPoint: function getObjectFromPoint(p) {
      var obj = this.triggersPanel.getObjectFromPoint(p);

      if (obj) {
        return obj;
      }

      var ret = undefined;
      var ctx = this.getContext();
      this.each(this._objects, function (o) {
        var obj = o.getObjectFromPoint(p, ctx);

        if (obj) {
          ret = obj;
        }

        return !ret;
      }, true);
      return ret;
    },
    _renderCrosshair: function _renderCrosshair(ctx, p, event) {
      this.canvasTransform();
      var pointData = "".concat(p.x, ",").concat(p.y);
      var CROSSHAIR_COLOR = '#ff0000';
      var CROSSHAIR_WIDTH = 15;
      var CROSSHAIR_LINE_WIDTH = 2;
      ctx.beginPath();
      ctx.strokeStyle = CROSSHAIR_COLOR;
      ctx.lineWidth = CROSSHAIR_LINE_WIDTH;
      ctx.moveTo(p.x - CROSSHAIR_WIDTH, p.y);
      ctx.lineTo(p.x + CROSSHAIR_WIDTH, p.y);
      ctx.moveTo(p.x, p.y - CROSSHAIR_WIDTH);
      ctx.lineTo(p.x, p.y + CROSSHAIR_WIDTH);
      ctx.stroke();
      ctx.beginPath();
      ctx.fillStyle = CROSSHAIR_COLOR;
      ctx.font = "15px sans-serif";
      ctx.fillText(pointData, p.x + 5, p.y - 5, 150);
      this.canvasResetTransform();
    },
    clear: function clear() {
      this._scale = 100;
      this._axesPoint = (0, _newCanvasObjects.Point)(0, 0);
      this._objects = [];
      this._blocks = [];
      this._connections = [];
      this.setState(this._awaitState); // this.commands = [];
      // this.lastCommand = -1;
    },
    setState: function setState(state) {
      var _this$_state;

      (_this$_state = this._state) === null || _this$_state === void 0 ? void 0 : _this$_state.reset();
      this._state = state;
      this.setCursor(state.getCursor());
      this.render();
    },
    setCursor: function setCursor(c) {
      this._canvas.style.cursor = c;
    },
    zoomIn: function zoomIn() {
      if (this._scale === MAXIMUM_ZOOM) {
        return; //prevent rerender
      }

      this._scale += ZOOM_STEP;

      if (this._scale > MAXIMUM_ZOOM) {
        this._scale = MAXIMUM_ZOOM;
      }

      this.render();
      this.saveCanvasSettings();
    },
    zoomOut: function zoomOut() {
      if (this._scale === MINIMUM_ZOOM) {
        return; //prevent rerender
      }

      this._scale -= ZOOM_STEP;

      if (this._scale < MINIMUM_ZOOM) {
        this._scale = MINIMUM_ZOOM;
      }

      this.saveCanvasSettings();
      this.render();
    },
    scrollUp: function scrollUp() {
      this._axesPoint.y += SCROLL_STEP;
      this.saveCanvasSettings();
      this.render();
    },
    scrollDown: function scrollDown() {
      this._axesPoint.y -= SCROLL_STEP;
      this.saveCanvasSettings();
      this.render();
    },
    scrollLeft: function scrollLeft() {
      this._axesPoint.x += SCROLL_STEP;
      this.saveCanvasSettings();
      this.render();
    },
    scrollRight: function scrollRight() {
      this._axesPoint.x -= SCROLL_STEP;
      this.saveCanvasSettings();
      this.render();
    },
    render: function render() {
      // console.error('RENDER CANVAS');
      this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);

      this.canvasTransform();

      this._renderAxesZero();

      this.renderObjects();

      this._state.render(this._ctx);

      this._renderTooltip();

      this._renderWarning();

      this.canvasResetTransform();

      this._renderZoom();
    },
    _renderAxesZero: function _renderAxesZero() {
      var ctx = this._ctx;
      ctx.beginPath();
      ctx.strokeStyle = '#00ff00';
      ctx.lineWidth = 3;
      ctx.moveTo(0, -100);
      ctx.lineTo(0, 100);
      ctx.moveTo(-100, 0);
      ctx.lineTo(100, 0);
      ctx.stroke();
      ctx.beginPath();
      ctx.fillStyle = '#000000';
      ctx.font = "15px sans-serif";
      var pointData = "".concat(this._axesPoint.x, ",").concat(this._axesPoint.y);
      ctx.fillText(pointData, 5, 20, 150);
    },
    _renderZoom: function _renderZoom() {
      var ctx = this._ctx;

      var _this$getSize = this.getSize(),
          width = _this$getSize.width,
          height = _this$getSize.height;

      ctx.beginPath();
      ctx.fillStyle = '#ffffff';
      ctx.strokeStyle = '#000000';
      ctx.lineWidth = 3;
      ctx.roundRect(10, height - 30, 100, 20, 5);
      ctx.fill();
      ctx.stroke();
      ctx.beginPath();
      ctx.fillStyle = '#000000';
      ctx.font = "15px sans-serif";
      ctx.fillText(this._scale + '%', 15, height - 15, 90);
    },
    _renderTooltip: function _renderTooltip() {
      var obj = this.tooltipObj;

      if (!obj) {
        return;
      }

      var ctx = this._ctx;

      var text = this._ctx.measureText(obj.tooltip);

      var textWidth = Math.ceil(text.width);
      var x = obj.min.x + obj.width / 2 - textWidth / 2;
      var y = obj.min.y - 27;
      ctx.beginPath();
      ctx.fillStyle = '#ffffff';
      ctx.strokeStyle = '#c5c5c5';
      ctx.lineWidth = 2;
      ctx.roundRect(x - 5, y, textWidth + 10, 25, 3);
      ctx.fill();
      ctx.stroke();
      ctx.beginPath();
      ctx.fillStyle = '#000000';
      ctx.font = "15px sans-serif";
      ctx.fillText(obj.tooltip, x, y + 20);
    },
    _renderWarning: function _renderWarning() {
      var obj = this.warningObj;

      if (!obj) {
        return;
      }

      var warning = obj.dynWarning || obj.warning;
      var ctx = this._ctx;

      var text = this._ctx.measureText(warning);

      var textWidth = Math.ceil(text.width); // const x = obj.min.x + obj.width / 2 - textWidth / 2;

      var x = obj.min.x;
      var y = obj.min.y - 27;
      ctx.beginPath();
      ctx.fillStyle = '#d50000';
      ctx.strokeStyle = '#c5c5c5';
      ctx.lineWidth = 2;
      ctx.roundRect(x - 5, y, textWidth + 10, 25, 3);
      ctx.fill();
      ctx.stroke();
      ctx.beginPath();
      ctx.fillStyle = '#000000';
      ctx.font = "15px sans-serif";
      ctx.fillText(warning, x, y + 20);
    },
    canvasTransform: function canvasTransform() {
      if (!this._translated) {
        this._ctx.translate(this._axesPoint.x, this._axesPoint.y);

        this._ctx.scale(this._scale / 100, this._scale / 100);

        this._translated = true;
      } else {
        console.error('SKIP TRANSLATE');
      }
    },
    canvasResetTransform: function canvasResetTransform() {
      if (this._translated) {
        this._ctx.translate(-this._axesPoint.x, -this._axesPoint.y);

        this._translated = false;

        this._ctx.resetTransform();
      } else {
        console.error('NOT TRANSLATED');
      }
    },
    renderObjects: function renderObjects() {
      var _this2 = this,
          _this$triggersPanel;

      this._objects.forEach(function (o) {
        o.render(_this2._ctx);
      });

      (_this$triggersPanel = this.triggersPanel) === null || _this$triggersPanel === void 0 ? void 0 : _this$triggersPanel.render(this._ctx);
    },
    renderObject: function renderObject(obj) {
      this.canvasTransform();
      obj.render(this._ctx);
      this.canvasResetTransform();
    },
    addConnection: function addConnection(conn) {
      this.onSaveConnection(conn);

      this._objects.addObject(conn);

      this.render();
    },
    removeConnection: function removeConnection(conn) {
      // const parentBlock = conn.outConnect.parentBlock;
      this._objects.removeObject(conn);

      conn.remove();
      this.onRemoveConnection(conn); //, parentBlock);

      this.render();
    },
    addBlock: function addBlock(block) {
      this._objects.addObject(block);

      this.render();
    },
    setBlocks: function setBlocks(blocks) {
      this._objects.setObjects(blocks);

      this.render();
    },
    removeBlock: function removeBlock(block) {
      var permanent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!permanent) {
        block.getInConnect().connections.forEach(function (c) {
          c.removed = true; // c.outConnect.removeConnection(c);
        });
        block.getOutConnect().connections.forEach(function (c) {
          c.removed = true; // c.inConnect?.removeConnection(c);
        });
        block.removed = true;
        this.render();
        var newComm = {
          block: block,
          action: 'remove',
          next: null,
          undo: function undo() {},
          redo: function redo() {}
        };
        this.addCommand(newComm);
        return;
      }

      this.hideTooltip();
      block.getInConnect().connections.forEach(function (c) {
        c.outConnect.removeConnection(c);
      });
      block.getOutConnect().connections.forEach(function (c) {
        var _c$inConnect;

        (_c$inConnect = c.inConnect) === null || _c$inConnect === void 0 ? void 0 : _c$inConnect.removeConnection(c);
      });

      this._objects.removeObjects(block.getInConnect().connections);

      this._objects.removeObjects(block.getOutConnect().connections);

      this._objects.removeObject(block);
    },
    getCurrentStateCursor: function getCurrentStateCursor(p) {
      // if (this.getMarkerFromPoint(p)) {
      //     return 'move';
      // }
      if (this.getObjectFromPoint(p)) {
        return 'pointer';
      }

      return this._state.getCursor(p);
    },
    each: function each(arr, callback, reverse) {
      var i = 0,
          len = arr && arr.length || 0;

      if (reverse) {
        while (len > 0) {
          len -= 1;

          if (callback(arr[len]) === false) {
            break;
          }
        }
      } else {
        while (i < len) {
          if (callback(arr[i]) === false) {
            break;
          }

          i += 1;
        }
      }
    },
    select: function select(obj, e) {
      this.deselect();
      obj.selected = true;
      this._selected = obj;
      obj.onSelect && obj.onSelect(e);
      this.render();
    },
    deselect: function deselect() {
      this._selected && (this._selected.selected = false);
      this._selected = null;
      this.render();
    },
    saveCanvasSettings: function saveCanvasSettings() {
      this.onSaveSettings();
    },
    setCanvasSettings: function setCanvasSettings(zoom, position) {
      this._scale = zoom || 100;
      this._axesPoint = position || (0, _newCanvasObjects.Point)(0, 0);
    },
    getCanvasSettings: function getCanvasSettings() {
      return {
        zoom: this._scale,
        position: this._axesPoint
      };
    },
    setSize: function setSize(_ref) {
      var width = _ref.width,
          height = _ref.height;
      this._canvas.width = width;
      this._canvas.height = height;
      this.render();
    },
    getSize: function getSize() {
      return {
        width: this._canvas.width,
        height: this._canvas.height
      };
    },
    showTooltip: function showTooltip(obj) {
      if (this.tooltipObj !== obj) {
        this.hideTooltip();
        this.tooltipObj = obj;
      }
    },
    hideTooltip: function hideTooltip() {
      if (this.tooltipObj) {
        this.tooltipObj = null;
      }
    },
    showWarning: function showWarning(obj) {
      if (this.warningObj !== obj) {
        this.hideWarning();
        this.warningObj = obj;
      }
    },
    hideWarning: function hideWarning() {
      if (this.warningObj) {
        this.warningObj = null;
      }
    }
  });
  _exports.Editor = Editor;
});
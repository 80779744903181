define("inboxfront/components/assistants/flows/settings", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    chatbots: Ember.inject.service(),
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.properties = this.sessionAccount.loggeduser.staffprofile.estates;
    },
    actions: {
      onSelectProperty: function onSelectProperty(val) {
        var hash = {
          companyProperty: val.id
        };
        this.set('flow.companyProperty', val);
        this.mutationFlow(hash);
      },
      onBlurInputFlow: function onBlurInputFlow(name, value) {
        this.onBlurInputFlow(name, value);
      },
      allowManualTrigger: function allowManualTrigger(val) {}
    },
    mutationFlow: function mutationFlow() {
      var _this = this;

      var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var data = _objectSpread(_objectSpread({}, hash), {}, {
        flowId: this.get('flow.id')
      });

      this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', data).then(function () {
        _this.onUpdate && _this.onUpdate();
      });
    }
  });

  _exports.default = _default;
});
define("inboxfront/components/channels/save-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.channelTypes = [{
        id: 1,
        component: "facebook",
        title: "Facebook messenger",
        type: 'FACEBOOK'
      }, {
        id: 2,
        component: "line",
        title: "LINE",
        type: 'LINE'
      }, {
        id: 3,
        component: "wechat",
        title: "WeChat",
        type: 'WECHAT'
      }, {
        id: 4,
        component: "telegram",
        title: "Telegram",
        type: 'TELEGRAM'
      }, {
        id: 5,
        component: "twitter",
        title: "Twitter DM",
        type: 'TWITTER'
      }, {
        id: 6,
        component: "whatsapp",
        title: "WhatsApp",
        type: 'WHATSAPP'
      }];
    },
    actions: {
      onCloseDialog: function onCloseDialog() {
        this.onCloseDialog();
      },
      onSelectType: function onSelectType(val) {
        this.set('channel', {});
        this.set('selectedChannelType', val);
      },
      onChannelCreated: function onChannelCreated(isClose, isNew) {
        this.onChannelCreated(isClose, isNew, this.get('channel'), this.get('instagramChannel'));
      },
      copyTxt: function copyTxt(name, title) {
        this.copyTxt(name, title);
      },
      showDelete: function showDelete(channel) {
        this.set('selectedChannel', channel);
        this.set('showDeleteDialog', true);
      },
      deleteChannel: function deleteChannel() {
        var channel = this.get('selectedChannel');
        var hash = {
          accountId: channel.id
        }; // this.ajax.mutation(Mutation.DELETE_ACCOUNT, 'deleteAccount', hash, () => {
        //     this.get('paperToaster').show('Channel deleted');
        //     this.set('selectedChannel', null);
        //     this.onDelete(channel);
        // });
      }
    }
  });

  _exports.default = _default;
});
define("inboxfront/services/chatbots", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.triggerLimitations = [{
        enum: 'A_12_HOURS',
        value: '12_HOURS',
        label: '12h',
        tooltip: 'Send once per 12 hours.'
      }, {
        enum: 'A_24_HOURS',
        value: '24_HOURS',
        label: '24h',
        tooltip: 'Send once per 24 hours.'
      }, {
        enum: 'ONCE',
        value: 'ONCE',
        label: '1',
        tooltip: 'Send only once.'
      }, {
        enum: null,
        value: null,
        label: '',
        tooltip: 'Send per each visit.'
      }];
      this.triggerOptions = [{
        name: 'first_visit',
        label: 'First-time visit',
        description: 'The bot starts when a new visitor lands on your website for the first time.',
        limited: false,
        icon: 'trigger-first-time'
      }, {
        name: 'mouse_leaves_window',
        label: 'Mouse leaves window',
        description: 'The bot starts when the visitor\'s mouse pointer leaves your website window for over a specified time.',
        limited: true,
        icon: 'trigger-mouse-leave-window'
      }, {
        name: 'specific_period',
        label: 'Specific period',
        description: 'The bot starts only on the specified days and times of the day.',
        limited: true,
        icon: 'trigger-specific-period'
      }, {
        name: 'return_visitor',
        label: 'Return visitor',
        description: 'The bot starts when the visitor returns to your site (is not a new visitor).',
        limited: true,
        icon: 'trigger-return-visitor'
      }, {
        name: 'scrolling_scroll_percentage',
        label: 'Current Page Time/Scroll',
        description: 'The bot will trigger when the visitor scrolls your website by a percentage or stays on your site for a set time.',
        limited: true,
        pageMeasure: 'time',
        pageTimeValue: 30,
        pageTimeMeasure: 'seconds',
        pageUrlRule: 'exact',
        pageScroll: '10%',
        isForPage: false,
        pageUrl: '',
        icon: 'trigger-current-page-scroll'
      }, {
        name: 'current_page',
        label: 'Current Page',
        description: 'The bot starts when the visitor opens a page you specified inside the trigger.',
        limited: true,
        pageUrlRule: 'exact',
        pageUrl: '',
        icon: 'trigger-current-page'
      }, {
        name: '"chat_initiated_by_visitor',
        label: 'Visitor clicks on chat button',
        description: 'The bot starts when the visitor clicks on the chat icon visible on your website.',
        limited: true,
        icon: 'trigger-visitor-starts-chat'
      }, {
        name: 'visitor_abandoned_form',
        label: '* Visitor abandoned form',
        textColor: 'orange',
        description: 'The bot starts when the visitor fills in the form and leaves it.',
        limited: true,
        icon: 'trigger-form-abandon'
      }, {
        name: 'instagram_story_reply',
        label: '* Instagram - Story Reply',
        textColor: 'orange',
        description: 'The bot starts when a visitor replies to your Instagram story.',
        limited: true,
        icon: 'trigger-instagram-story-reply'
      }, {
        name: 'visitor_adds_to_a_cart',
        label: '* Visitor adds to a cart',
        textColor: 'orange',
        description: 'The bot will trigger when visitors add an item to their shopping cart. It only works when the integration with Shopify/Big-Commerce is activated.',
        limited: false,
        icon: 'trigger-add-to-cart'
      }, {
        name: 'agent_starts_chatbot',
        label: '* The operator starts the chatbot',
        textColor: 'orange',
        description: 'The bot starts when the operator triggers the bot during a chat conversation. The bot will take over the conversation.',
        limited: false,
        icon: 'trigger-agent-starts-chatbot'
      }, {
        name: 'agent_is_idle',
        label: 'The operator is idle',
        description: 'The chatbot starts when an operator is assigned to a conversation but has not responded for a specified time.',
        limited: false,
        idleTimeValue: '',
        idleTimeMeasure: 'minutes',
        idleTimeoutValue: '',
        idleTimeoutMeasure: 'minutes',
        icon: 'trigger-agent-is-idle'
      }, {
        name: 'unassigned_conversation',
        label: 'Unassigned conversation',
        description: 'The chatbot starts when the operator hasn\'t opened a new incoming chat (it remains unassigned).',
        limited: false,
        idleTimeValue: '',
        idleTimeMeasure: 'seconds',
        icon: 'trigger-unassigned-conversation'
      }, {
        name: 'operator_marks_conversation_as_solved',
        label: '* The operator marks conversation as solved',
        textColor: 'orange',
        description: 'The bot starts when the operator clicks on the "close conversation" button in the chat conversation.',
        limited: false,
        icon: 'trigger-marks-as-solved'
      }, {
        name: 'from_another_chatbot',
        label: '* From another chatbot',
        textColor: 'orange',
        description: 'Triggers when there is a paired "To another chatbot" node is issued from another chatbot.',
        limited: false,
        icon: 'trigger-another-chatbot'
      }];
      this.components = [{
        name: 'Message',
        type: 'message',
        icon: 'cb-actions-message',
        blockType: 'TEXT'
      }, {
        name: 'Options (Single select)',
        type: 'option_single_select',
        icon: 'cb-actions-options-single',
        blockType: 'TEXT'
      }, {
        name: 'Options (Cards)',
        type: 'options_cards',
        icon: 'cb-actions-options-cards',
        blockType: 'SLIDER'
      }, {
        name: 'Data Collector (form)',
        type: 'form',
        icon: 'cb-actions-data-collector',
        blockType: 'FORM'
      }, {
        name: 'Randomize',
        type: 'random',
        icon: 'cb-actions-randomize',
        blockType: 'TEXT'
      }, {
        name: 'Add/Remove a tag',
        type: 'add_remove_tag',
        icon: 'cb-actions-add-tag',
        blockType: 'TEXT'
      }, {
        name: 'Voucher/Promo Code',
        type: 'promo_code',
        icon: 'cb-actions-promo',
        blockType: 'TEXT'
      }, {
        name: 'Subscribe for mailing',
        type: 'subscribe',
        icon: 'cb-actions-subscribe',
        blockType: 'TEXT'
      }, {
        name: 'Transfer to Operator',
        type: 'old_action_assign_to',
        icon: 'cb-actions-assign-to',
        blockType: 'TEXT'
      }, {
        name: '* Transfer to a department',
        type: 'assign_to_department',
        icon: 'cb-actions-assign-to-dep',
        blockType: 'TEXT'
      }, {
        name: 'Create a Support Ticket',
        type: 'old_action_create_ticket',
        icon: 'cb-actions-create-ticket',
        blockType: 'TEXT'
      }, {
        name: '* Continue with Another Chatbot',
        type: 'go_to_chatbot',
        icon: 'cb-actions-go-to-chatbot',
        blockType: 'TEXT'
      }];
      this.additionalConditions = [{
        name: 'Current Page',
        type: 'condition',
        icon: 'single',
        blockType: 'TEXT'
      }, {
        name: 'Contact Property',
        type: 'condition',
        icon: 'single',
        blockType: 'TEXT'
      }, {
        name: 'Return Visitor',
        type: 'condition',
        icon: 'single',
        blockType: 'TEXT'
      }, {
        name: '* Browser',
        type: 'condition',
        icon: 'single',
        blockType: 'TEXT'
      }, {
        name: '* Language',
        type: 'condition',
        icon: 'single',
        blockType: 'TEXT'
      }, {
        name: 'Mobile',
        type: 'condition',
        icon: 'single',
        blockType: 'TEXT'
      }, {
        name: 'Operator Status',
        type: 'condition',
        icon: 'single',
        blockType: 'TEXT'
      }];
    },
    getById: function getById(chatbotId) {
      var _this = this;

      return new Promise(function (resolve) {
        _this.ajax.query(_query.default.CHATBOT, 'chatbot', {
          flowId: chatbotId
        }).then(function (rawChatbot) {
          if (rawChatbot) {
            var chatbot = _this._deserealizeChatbot(rawChatbot);

            _this.validate(chatbot);

            resolve(chatbot);
          } else {
            resolve(null);
          }
        });
      });
    },
    ping: function ping(flowId) {
      this.ajax.query(_query.default.CHATBOT, 'chatbot', {
        flowId: flowId
      }).then(function (chatbot) {});
    },
    _deserealizeChatbot: function _deserealizeChatbot(rawChatbot) {
      var extraData;

      try {
        extraData = JSON.parse(rawChatbot.extraData || '{}');
      } catch (e) {
        console.error(e);
        extraData = {};
      }

      return _objectSpread(_objectSpread({}, rawChatbot), {}, {
        extraData: extraData,
        triggers: this._deserealizeTriggers(rawChatbot.triggers),
        blocks: this._deserealizeBlocks(rawChatbot.newBlocks)
      });
    },
    _deserealizeTriggers: function _deserealizeTriggers(rawTriggers) {
      var triggerOptions = this.triggerOptions;
      triggerOptions.forEach(function (trigOption) {
        Ember.set(trigOption, 'disabled', false);
      });
      var triggers = [];

      if (rawTriggers) {
        var limits = this.triggerLimitations;
        rawTriggers.forEach(function (t) {
          var _t$rules$conditions;

          if (t.rules) {
            try {
              t.rules = JSON.parse(t.rules);
            } catch (e) {
              console.error(e);
            }
          }

          if (((_t$rules$conditions = t.rules.conditions) === null || _t$rules$conditions === void 0 ? void 0 : _t$rules$conditions.all) && t.rules.conditions.all[0]) {
            var trig = triggerOptions.findBy('name', t.rules.conditions.all[0].name);

            if (trig) {
              Ember.set(trig, 'disabled', true);

              var trigger = _objectSpread({}, trig);

              trigger.ruleId = t.id;

              if (trigger.limited) {
                var limit = limits.findBy('enum', t.livechatTriggerLimit);
                trigger.livechatTriggerLimit = (limit === null || limit === void 0 ? void 0 : limit.value) || '12_HOURS'; //TODO use value from triggerLimitations instead of constant
              }

              var extraData;

              switch (t.rules.conditions.all[0].name) {
                case 'scrolling_scroll_percentage':
                  extraData = JSON.parse(t.rules.conditions.all[0].extraData || '{}');
                  trigger.pageMeasure = extraData['pageMeasure'] || 'time';
                  trigger.pageTimeValue = extraData['pageTimeValue'] || 30;
                  trigger.pageTimeMeasure = extraData['pageTimeMeasure'] || 'seconds';
                  trigger.pageScroll = extraData['pageScroll'] || '10%';
                  trigger.isForPage = extraData['isForPage'] || false;
                  trigger.pageUrlRule = extraData['pageUrlRule'] || 'exact';
                  trigger.pageUrl = extraData['pageUrl'] || '';
                  break;

                case 'current_page':
                  extraData = JSON.parse(t.rules.conditions.all[0].extraData || '{}');
                  trigger.pageUrlRule = extraData['pageUrlRule'] || 'exact';
                  trigger.pageUrl = extraData['pageUrl'] || '';
                  break;

                case 'agent_is_idle':
                  extraData = JSON.parse(t.rules.conditions.all[0].extraData || '{}');
                  trigger.idleTimeValue = extraData['idleTimeValue'] || '';
                  trigger.idleTimeMeasure = extraData['idleTimeMeasure'] || 'minutes';
                  trigger.idleTimeoutValue = extraData['idleTimeoutValue'] || '';
                  trigger.idleTimeoutMeasure = extraData['idleTimeoutMeasure'] || 'minutes';
                  break;

                case 'unassigned_conversation':
                  extraData = JSON.parse(t.rules.conditions.all[0].extraData || '{}');
                  trigger.idleTimeValue = extraData['idleTimeValue'] || '';
                  trigger.idleTimeMeasure = extraData['idleTimeMeasure'] || 'seconds';
                  break;

                default:
              }

              triggers.addObject(trigger);
            }
          }
        });
      }

      return triggers;
    },
    _deserealizeBlocks: function _deserealizeBlocks(rawBlocks) {
      rawBlocks.forEach(function (b) {
        var extraData;

        try {
          extraData = JSON.parse(b.extraData);
        } catch (e) {
          extraData = {};
        }

        b.extraData = extraData;
      });
      return rawBlocks;
    },
    saveChatbotSettings: function saveChatbotSettings(extraData, flowId) {
      var data = {
        extraData: JSON.stringify(extraData),
        flowId: flowId
      };
      this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', data).then(function () {});
    },
    saveNewBlock: function saveNewBlock(blockToSave) {
      var hash = {
        flowId: blockToSave.flow.id,
        mainTitle: blockToSave.mainTitle,
        blockType: blockToSave.blockType,
        name: blockToSave.name,
        firstBlock: blockToSave.firstBlock,
        childBlockIds: [],
        extraData: JSON.stringify(blockToSave.extraData)
      };

      if (blockToSave.id) {
        hash.blockId = blockToSave.id;
      }

      this.ajax.query(_mutation.default.CREATE_UPDATE_NEW_FLOW_BLOCK, 'createUpdateNewFlowBlock', hash).then(function (_ref) {
        var block = _ref.block;

        if (!blockToSave.id) {
          Ember.set(blockToSave, 'id', block.id);
        }
      });
    },
    validate: function validate(chatbot) {
      var _this2 = this;

      var isValid = true;
      chatbot.blocks.forEach(function (b) {
        _this2.validateBlock(b, chatbot);

        if (!b.isValid) {
          isValid = false;
        }
      });
      Ember.set(chatbot, 'isValid', isValid);
      return isValid;
    },
    validateBlock: function validateBlock(block, flow) {
      var isValid = true;
      var error = '';

      if (block.name === 'Randomize') {
        var extraData = block.extraData;

        if (typeof block.extraData === 'string') {
          try {
            extraData = JSON.parse(block.extraData);
          } catch (e) {}
        }

        if (extraData.connections.length < 3) {
          isValid = false;
          error = 'Connect at least to 3 blocks';
        }
      } else if (block.name === 'Add/Remove a tag') {} else {
        if (!block.mainTitle.trim() || block.mainTitle === '<br>') {
          isValid = false;
          error = 'Message field is empty';
        } else {
          if (block.name === 'Data Collector (form)') {
            var _extraData = block.extraData;

            if (typeof block.extraData === 'string') {
              try {
                _extraData = JSON.parse(block.extraData);
              } catch (e) {}
            }

            if (_extraData.dataCollectors && _extraData.dataCollectors.length) {
              _extraData.dataCollectors.forEach(function (f) {
                if (!f.dataType) {
                  isValid = false;
                  error = 'Select field datatype';
                }
              });
            } else {
              isValid = false;
              error = 'One field to collect is required';
            }
          } else if (block.name === 'Options (Single select)') {
            var _extraData2 = block.extraData;

            if (typeof block.extraData === 'string') {
              try {
                _extraData2 = JSON.parse(block.extraData);
              } catch (e) {}
            }

            if (_extraData2.options && _extraData2.options.length) {
              _extraData2.options.forEach(function (f) {
                if (!f.mainTitle) {
                  isValid = false;
                  error = 'Some fields are empty or invalid';
                }
              });

              if (isValid) {
                var _extraData2$connectio;

                if (_extraData2.options.length !== ((_extraData2$connectio = _extraData2.connections) === null || _extraData2$connectio === void 0 ? void 0 : _extraData2$connectio.length)) {
                  isValid = false;
                  error = 'Connect each option to block';
                }
              }
            } else {
              isValid = false;
              error = 'Some fields are empty or invalid';
            }
          } else if (block.name === 'Voucher/Promo Code') {
            var _extraData3 = block.extraData;

            if (typeof block.extraData === 'string') {
              try {
                _extraData3 = JSON.parse(block.extraData);
              } catch (e) {}
            }

            if (!_extraData3.promoCode) {
              isValid = false;
              error = 'Promo code is required';
            }
          } else if (block.name === 'Subscribe for mailing') {} else if (block.name === 'Transfer to Operator') {}
        }
      }

      if (isValid) {
        var hasConnect = false;
        var _extraData4 = flow.extraData;

        if (typeof flow.extraData === 'string') {
          try {
            _extraData4 = JSON.parse(flow.extraData);
          } catch (e) {}
        }

        if (_extraData4.firstBlock) {
          if (_extraData4.firstBlock === block.id) {
            hasConnect = true;
          }
        }

        hasConnect || flow.newBlocks.forEach(function (b) {
          if (b.id !== block.id) {
            var _extraData5 = b.extraData;

            if (typeof b.extraData === 'string') {
              try {
                _extraData5 = JSON.parse(b.extraData);
              } catch (e) {}
            }

            if (_extraData5.connections) {
              if (_extraData5.connections.indexOf(block.id) >= 0) {
                hasConnect = true;
              }
            }
          }
        });

        if (!hasConnect) {
          isValid = false;
          error = 'Block is not connected proper';
        }
      }

      Ember.set(block, 'isValid', isValid);
      Ember.set(block, 'error', error);
    },
    removeNewBlock: function removeNewBlock(block) {
      this.ajax.query(_mutation.default.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {
        blockId: block.id
      }).then(function (res) {});
    },
    _setBlockDefaults: function _setBlockDefaults(block, type) {
      var _ref2;

      var component = (_ref2 = []).concat.apply(_ref2, _toConsumableArray(this.get('components').map(function (componentGroup) {
        return componentGroup.items;
      }))).findBy('type', type);

      block.blockType = component.blockType;
      block.name = component.name;
      block.icon = component.icon;
      block.color = component.color;
      block.type = component.type;
    },
    addBlock: function addBlock(component, after, workflow, onAdd) {
      var _this3 = this;

      var block = _objectSpread(_objectSpread({}, component), {}, {
        parent: after,
        children: after.children
      });

      if (!this.get('dummyId')) {
        this.set('dummyId', -1);
      }

      if (block.blockType === 'IFELSE') {
        var branch1 = {
          blockType: 'BRANCH',
          parent: block,
          children: after.children,
          name: 'Branch 1',
          position: 0,
          id: this.get('dummyId')
        };
        this.set('dummyId', this.get('dummyId') - 1);
        var branch2 = {
          blockType: 'BRANCH',
          parent: block,
          children: [],
          name: 'Branch 2',
          position: 1,
          id: this.get('dummyId')
        };
        this.set('dummyId', this.get('dummyId') - 1);
        block.children = [branch1, branch2];
      } else if (block.blockType === 'TICKET' || block.blockType === 'TASK') {
        Ember.set(block, 'status', 'OPEN');
        Ember.set(block, 'priority', 'LOW');
        Ember.set(block, 'periodTimes', 'minutes');
      } else if (block.blockType === 'EMAIL') {
        Ember.set(block, 'to', '{{Customer Email}}');
      } else if (block.blockType === 'DUEDATE') {
        Ember.set(block, 'period', 0);
        Ember.set(block, 'periodTimes', 'minutes');
      }

      this.saveBlock(block, workflow).then(function () {
        if (block.blockType === 'IFELSE') {
          workflow.blocks.addObject(block.children[0]);
          workflow.blocks.addObject(block.children[1]);

          if (block.children[0].children[0]) {
            block.children[0].children[0].parent = block.children[0];

            _this3.saveBlock(block.children[0].children[0], workflow);
          }
        } else {
          if (block.children[0]) {
            block.children[0].parent = block;

            _this3.saveBlock(block.children[0], workflow);
          }
        }

        Ember.set(after, 'children', [block]);

        if (after.id) {
          if (after.blockType === 'BRANCH') {
            _this3.saveBlock(after.parent, workflow);
          } else {
            _this3.saveBlock(after, workflow);
          }
        } else {
          _this3.updateTrigger(workflow);
        }

        workflow.blocks.addObject(block);

        _this3.validateWorkflow(workflow);

        onAdd && onAdd(block);
      }).catch(function (e) {
        console.error('ADD BLOCK', block, e);
      });
    },
    clearVariables: function clearVariables(text) {
      var parts = text.split('{{');

      if (parts.length) {
        text = '';
        parts.forEach(function (p) {
          if (p.indexOf('}}') >= 0) {
            var vars = p.split('}}');
            vars[0] = vars[0].split(' ').join('_').toLowerCase();
            text += '{' + vars[0] + '}' + vars[1];
          } else {
            text += p;
          }
        });
      }

      return text;
    },
    restoreVariables: function restoreVariables(text) {
      var parts = text.split('{');

      if (parts.length) {
        text = '';
        parts.forEach(function (p) {
          if (p.indexOf('}') >= 0) {
            var vars = p.split('}');
            var words = vars[0].split('_');

            for (var i = 0, l = words.length; i < l; i++) {
              words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);

              if (words[i].indexOf('}') >= 0) {
                break;
              }
            }

            vars[0] = words.join(' ');
            text += '{{' + vars[0] + '}}' + vars[1];
          } else {
            text += p;
          }
        });
      }

      return text;
    },
    saveBlock: function saveBlock(block, workflow, onSave) {
      var _this4 = this;

      var rules = {};

      if (block.blockType === 'IFELSE') {
        if (!block.rules) {
          block.rules = [{
            conditions: {},
            actions: []
          }, {
            conditions: {},
            actions: []
          }];
        }

        block.rules[0].name = block.children[0].name;
        block.rules[1].name = block.children[1].name;
        block.rules[0].if = block.ifConditions;
        block.rules[1].then = block.thenConditions;
        block.rules[0].conditions = this.formatConditions(block.ifConditions);
        block.rules[1].conditions = this.formatConditions(block.thenConditions);
        block.ifConditions && block.ifConditions.length && console.log('IF CONDITIONS', block.rules[0].conditions);
        block.thenConditions && block.thenConditions.length && console.log('THEN CONDITIONS', block.rules[1].conditions);

        if (!block.thenConditions || !block.thenConditions.length) {
          var then = [];
          block.rules[0].conditions.any && block.rules[0].conditions.any.forEach(function (c) {
            var n = _objectSpread({}, c);

            if (c.operator === 'equal_to') {
              n.operator = 'not_equal_to';
            }

            then.addObject(n);
          });
          block.rules[1].conditions.all = then;
          console.log('REVERSE CONDITIONS', block.rules[0].conditions, 'TO', block.rules[1].conditions);
        }
      } else {
        if (!block.rules) {
          block.rules = {};
        }

        if (!block.rules.actions) {
          block.rules.actions = [];
        }

        if (block.blockType === 'EMAIL') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'send_email',
              params: {
                sender: '',
                to: '{{Customer Email}}',
                subject: '',
                body: ''
              }
            };
          }

          rules = block.rules;
          block.sender && (rules.actions[0].params.sender = +block.sender.pk);
          rules.actions[0].params.to = block.to ? this.clearVariables(block.to) : '';
          rules.actions[0].params.subject = block.subject ? this.clearVariables(block.subject) : '';
          rules.actions[0].params.body = block.body ? this.clearVariables(block.body) : '';
        } else if (block.blockType === 'INTERNAL') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'send_internal',
              params: {
                model: '',
                users: [],
                rooms: [],
                recipients: [],
                description: '',
                message: ''
              }
            };
          }

          rules = block.rules;
          block.model && block.model.id && (rules.actions[0].params.model = block.model.id);
          block.users && (rules.actions[0].params.users = block.users);
          block.rooms && (rules.actions[0].params.rooms = block.rooms);
          block.recipients && (rules.actions[0].params.recipients = block.recipients.map(function (r) {
            return r.id;
          }));
          rules.actions[0].params.description = block.description || '';
          rules.actions[0].params.message = block.message || '';
        } else if (block.blockType === 'COMMENT') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'add_comment',
              params: {
                module: '',
                comment: ''
              }
            };
          }

          rules = block.rules;
          block.module && (rules.actions[0].params.module = block.module.id);
          rules.actions[0].params.comment = block.comment || '';
        } else if (block.blockType === 'TICKET' || block.blockType === 'TASK') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'create_ticket',
              params: {
                subject: '',
                department: '',
                assignee: '',
                status: 'OPEN',
                priority: 'LOW',
                period: 0,
                tag_list: [],
                task_type: block.blockType
              }
            };
          }

          rules = block.rules;
          rules.actions[0].params.subject = block.subject || '';
          block.department && (rules.actions[0].params.department = block.department.id);
          block.assignee && (rules.actions[0].params.assignee = block.assignee.id);
          block.status && (rules.actions[0].params.status = block.status);
          block.priority && (rules.actions[0].params.priority = block.priority);
          var period = block.period || 0;

          if (block.periodTimes === 'days') {
            period = period * 3600;
          } else if (block.periodTimes === 'hours') {
            period = period * 60;
          }

          rules.actions[0].params.period = period;
          block.tags && (rules.actions[0].params.tag_list = block.tags.length > 0 ? block.tags : []);
          block.periodTimes && (rules.actions[0].params.times = block.periodTimes);
        } else if (block.blockType === 'CONTACT') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'update_contact',
              params: {
                first_name: '',
                last_name: '',
                email: '',
                contact_owner: ''
              }
            };
          }

          rules = block.rules;
          rules.actions[0].params.first_name = block.firstName || '';
          rules.actions[0].params.last_name = block.lastName || '';
          rules.actions[0].params.email = block.email || '';
          block.owner && (rules.actions[0].params.contact_owner = block.owner.id);
        } else if (block.blockType === 'TAG') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'add_tags',
              params: {
                module: '',
                tag_list: []
              }
            };
          }

          rules = block.rules;
          block.module && (rules.actions[0].params.module = block.module.id);
          rules.actions[0].params.tag_list = block.tag_list || [];
        } else if (block.blockType === 'STATUS') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'set_status',
              params: {
                status: ''
              }
            };
          }

          rules = block.rules;
          block.status && (rules.actions[0].params.status = block.status);
        } else if (block.blockType === 'DUEDATE') {
          if (!block.rules.actions[0]) {
            block.rules.actions[0] = {
              name: 'set_due_date',
              params: {
                due_date: ''
              }
            };
          }

          rules = block.rules;

          var _period = block.period || 0;

          if (block.periodTimes === 'days') {
            _period = _period * 3600;
          } else if (block.periodTimes === 'hours') {
            _period = _period * 60;
          }

          rules.actions[0].params.due_date = _period;
          block.periodTimes && (rules.actions[0].params.times = block.periodTimes);
        } else if (block.blockType === 'TERMINATE') {
          if (!block.rules.type) {
            block.rules.type = block.type;
          }
        }
      }

      var parentId = block.parent.id;

      if (block.parent.blockType === 'BRANCH') {
        parentId = block.parent.parent.id;
      }

      if (block.blockType === 'IFELSE') {
        if (block.children[0].children[0]) {
          block.rules[0].actions[0] = {
            name: 'run_next',
            params: {
              block_id: +block.children[0].children[0].id
            }
          };
        } else {
          block.rules[0].actions = [];
        }

        if (block.children[1].children[0]) {
          block.rules[1].actions[0] = {
            name: 'run_next',
            params: {
              block_id: +block.children[1].children[0].id
            }
          };
        } else {
          block.rules[1].actions = [];
        }
      } else {
        if (!block.parent.id) {
          block.rules.conditions = workflow.trigger.conditions;
        } else {
          if (block.parent.blockType === 'BRANCH') {
            if (block.parent === block.parent.parent.children[0]) {
              block.rules.conditions = block.parent.parent.rules[0].conditions;
            } else {
              block.rules.conditions = block.parent.parent.rules[1].conditions;
            }
          } else {
            block.rules.conditions = block.parent.rules.conditions;
          }
        }

        if (block.children[0]) {
          block.rules.actions[1] = {
            name: 'run_next',
            params: {
              block_id: +block.children[0].id
            }
          };
        } else {
          if (block.blockType === 'TERMINATE') {
            block.rules.actions = [];
          } else {
            block.rules.actions[1] = {};
          }
        }
      }

      var data = {
        parentId: parentId,
        rules: JSON.stringify(block.rules),
        workflowId: workflow.id,
        description: block.description
      };

      if (block.id) {
        data.blockId = block.id;

        if (!parentId) {
          data.parentId = 0;
        }
      }

      return this.ajax.query(_mutation.default.CREATE_UPDATE_WORKFLOW_BLOCK, 'createOrUpdateWorkflowBlock', data).then(function (res) {
        if (res.errors) {
          console.error('createOrUpdateWorkflowBlock', res.errors[0].message, 'DATA:', data);
        }

        if (res.block) {
          if (!block.id) {
            Ember.set(block, 'id', res.block.id);
          }

          _this4.validateBlock(block, workflow);

          onSave && onSave();
        }
      });
    },
    replaceVariable: function replaceVariable(text) {
      return text;
    },
    deleteBlock: function deleteBlock(block, workflow) {
      var _this5 = this;

      var requests = [];

      if (block.blockType === 'IFELSE') {
        if (block.children[0].children[0]) {
          Ember.set(block.parent, 'children', block.children[0].children);
          block.children[0].children[0].parent = block.parent;
          requests.addObject(this.saveBlock(block.children[0].children[0], workflow));
        } else {
          Ember.set(block.parent, 'children', block.children[1].children);

          if (block.children[1].children[0]) {
            block.children[1].children[0].parent = block.parent;
            requests.addObject(this.saveBlock(block.children[1].children[0], workflow));
          }
        }

        block.children.forEach(function (b) {
          workflow.blocks.removeObject(b);
        });
      } else {
        Ember.set(block.parent, 'children', block.children);

        if (block.children[0]) {
          block.children[0].parent = block.parent;
          requests.addObject(this.saveBlock(block.children[0], workflow));
        }
      }

      if (!block.parent.id) {
        requests.addObject(this.updateTrigger(workflow));
      } else {
        if (block.parent.blockType === 'BRANCH') {
          requests.addObject(this.saveBlock(block.parent.parent, workflow));
        } else {
          requests.addObject(this.saveBlock(block.parent, workflow));
        }
      }

      return new Promise(function (resolve) {
        Ember.RSVP.all(requests).then(function () {
          _this5.ajax.mutation(_mutation.default.DELETE_WORKFLOW_BLOCK, 'deleteWorkflowBlock', {
            blockId: block.id
          }, function () {
            workflow.blocks.removeObject(block);

            _this5.validateWorkflow(workflow);

            resolve();
          });
        }).catch(function (e) {
          console.error(e);
        });
      });
    },
    updateTrigger: function updateTrigger(workflow, objectType) {
      if (workflow.triggerBlock.children[0]) {
        workflow.trigger.actions[0] = {
          name: 'run_next',
          params: {
            block_id: +workflow.triggerBlock.children[0].id
          }
        };
      } else {
        workflow.trigger.actions = [];
      }

      var data = {
        workflowId: workflow.id,
        trigger: JSON.stringify(workflow.trigger)
      };

      if (objectType) {
        data.objectType = objectType;
        Ember.set(workflow, 'objectType', objectType);
      }

      this.validateWorkflow(workflow);
      return this.ajax.mutation(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', data);
    },
    validateBlockOld: function validateBlockOld(block, workflow) {
      var valid = false;

      if (block.blockType === 'EMAIL') {
        valid = true;

        if (valid && !block.sender) {
          valid = false;
        }

        if (valid && block.to) {
          if (block.to !== '{{Customer Email}}') {
            valid = this.validateEmail(block.to);
          }
        } else {
          valid = false;
        }

        if (valid) {
          if (!block.subject || block.subject.trim() === '') {
            valid = false;
          }
        }

        if (valid) {
          if (!block.body || block.body.trim() === '') {
            valid = false;
          }
        }
      } else if (block.blockType === 'TICKET' || block.blockType === 'TASK') {
        valid = true;

        if (valid) {
          if (!block.subject || block.subject.trim() === '') {
            valid = false;
          }
        }

        if (valid && !block.department) {
          valid = false;
        }

        if (valid && !block.status) {
          valid = false;
        }

        if (valid && !block.priority) {
          valid = false;
        } // if (valid && !block.period) {
        //     valid = false;
        // }

      } else if (block.blockType === 'INTERNAL') {
        valid = true;

        if (!block.description || block.description.trim() === '') {
          valid = false;
        }

        if (!block.message || block.message.trim() === '') {
          valid = false;
        }

        if (valid && !block.model) {
          valid = false;
        }

        if (block.recipients && !block.recipients.length) {
          valid = false;
        }
      } else if (block.blockType === 'COMMENT') {
        valid = true;

        if (!block.comment || block.comment.trim() === '') {
          valid = false;
        }

        if (valid && !block.module) {
          valid = false;
        }
      } else if (block.blockType === 'STATUS') {
        valid = true;

        if (!block.status) {
          valid = false;
        }
      } else if (block.blockType === 'IFELSE') {
        valid = true;

        if (!block.description) {
          valid = false;
        }

        if (!block.ifConditions || !block.ifConditions.length) {
          valid = false;
        }
      } else if (block.blockType === 'TAG') {
        valid = true;

        if (!block.tag_list || !block.tag_list.length) {
          valid = false;
        }

        if (valid && !block.module) {
          valid = false;
        }
      } else if (block.blockType === 'DUEDATE') {
        valid = true;

        if (isNaN(block.period) || block.period < 0) {
          valid = false;
        }
      } else if (block.blockType === 'TERMINATE') {
        valid = true;
      } else if (block.blockType === 'BRANCH') {
        valid = true;
      }

      Ember.set(block, 'isValid', valid);
      this.validateWorkflow(workflow);
    },
    validateEmail: function validateEmail(value) {
      var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(value);
    },
    validateWorkflow: function validateWorkflow(workflow) {
      var valid = !!(workflow.title && workflow.title.trim());

      if (valid) {
        if (!(workflow.trigger.conditions.any && workflow.trigger.conditions.any.length) && !(workflow.trigger.conditions.all && workflow.trigger.conditions.all.length)) {
          valid = false;
        }

        valid = valid && workflow.blocks.length > 1;
        valid && workflow.blocks.forEach(function (b) {
          if (b.blockType === 'BRANCH') {
            return;
          }

          if (valid && !b.isValid) {
            valid = false;
          }
        });

        if (valid) {
          valid = this.checkTerminator(workflow.triggerBlock);
        }
      }

      Ember.set(workflow, 'isValid', valid);
    },
    checkTerminator: function checkTerminator(block) {
      if (!block.children.length) {
        //only terminator block without children
        return block.blockType === 'TERMINATE';
      } else if (block.children.length === 1) {
        //move forward
        return this.checkTerminator(block.children[0]);
      } else {
        // branches
        return this.checkTerminator(block.children[0]) && this.checkTerminator(block.children[1]);
      }
    },
    formatConditions: function formatConditions(rules) {
      var conditions = {};
      var any = [];
      var all = [];
      rules && rules.forEach(function (r, index) {
        var rule = {};

        if (!(r.selectedModule && r.selectedRule && r.selectedOption)) {
          return;
        }

        if (r.selectedModule === 'Ticket/Task') {
          switch (r.selectedRule) {
            case 'Status':
              rule.name = 'status';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.id.toLowerCase();
              break;

            case 'Priority':
              rule.name = 'priority';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.id.toLowerCase();
              break;

            case 'Issue Type':
              rule.name = 'issue_type';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.id;
              break;

            case 'Source':
              rule.name = 'source';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.id.toLowerCase();
              break;

            case 'Department':
              rule.name = 'department';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.pk;
              break;

            case 'Assignee':
              rule.name = 'assignee';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.pk;
              break;

            case 'Attachment':
              rule.name = 'attachment';
              rule.operator = r.selectedOption.name === 'Is true' ? 'is_true' : 'is_false';
              rule.value = '';
              break;
          }
        } else if (r.selectedModule === 'Conversation') {
          switch (r.selectedRule) {
            case 'Channel/source':
              rule.name = 'channel';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.id.toLowerCase();
              break;

            case 'Assignee':
              rule.name = 'assignee';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.pk;
              break;

            case 'Country':
              rule.name = 'country';
              rule.operator = 'equal_to';
              rule.value = r.selectedOption.iso2;
              break;

            case 'Refund':
              rule.name = 'refund';
              rule.operator = r.selectedOption.name === 'Is true' ? 'is_true' : 'is_false';
              rule.value = '';
              break;

            case 'Cancel':
              rule.name = 'cancel';
              rule.operator = r.selectedOption.name === 'Is true' ? 'is_true' : 'is_false';
              rule.value = '';
              break;

            case 'Profile qualification':
              rule.name = 'profile_is_complited';
              rule.operator = r.selectedOption.name === 'Is complete' ? 'is_true' : 'is_false';
              rule.value = '';
              break;
          }
        } else {
          console.error('WRONG RULE', r);
          return;
        }

        if (rules[index - 1]) {
          if (rules[index - 1].operator === 'AND') {
            all.addObject(rule);
          } else {
            any.addObject(rule);
          }
        } else {
          if (r.operator === 'AND') {
            all.addObject(rule);
          } else {
            any.addObject(rule);
          }
        }
      });

      if (any.length) {
        conditions.any = any;
      }

      if (all.length) {
        conditions.all = all;
      }

      return conditions;
    }
  });

  _exports.default = _default;
});
define("inboxfront/controllers/flows/tmpl", ["exports", "jquery", "inboxfront/config/environment", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _jquery, _environment, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var WORKFLOW = 2;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    workflows: Ember.inject.service(),
    validation: Ember.inject.service(),
    isActiveFlow: false,
    isShowComponent: false,
    isShowListComponents: false,
    isActiveStartBlockFlow: true,
    reRender: true,
    selectedFlowTab: 'settings',
    canvasZoom: 100,
    firstBlock: Ember.computed('blocks.[]', function () {
      if (this.get('isChatBot')) {
        return this.get('blocks').findBy('parent', null);
      } else {
        return this.get('blocks').findBy('parent.id', null);
      }
    }),
    componentsFlow: Ember.computed('flow.{type,objectType}', 'flow', 'selectedComponent', function () {
      if (this.get('isChatBot')) {
        return this.get('flowComponents');
      }

      return this.workflows.getComponents(this.get('flow'), this.get('selectedComponent'));
    }),
    conditionModules: Ember.computed('flow.objectType', function () {
      if (this.get('flow.objectType') === 'CONVERSATION') {
        return ['Conversation'];
      } else {
        return ['Ticket/Task'];
      }
    }),
    isWorkflow: Ember.computed('flow.type', function () {
      return this.get('flow.type') === WORKFLOW;
    }),
    isChatBot: Ember.computed('isWorkflow', function () {
      return !this.get('isWorkflow');
    }),
    isDisabledFlowActive: Ember.computed('blocks.[]', 'selectedComponent.{children.@each.attachments.0.filename}', 'selectedComponent.{children.@each.mainTitle}', 'selectedComponent.{children.@each.subtitle}', 'selectedComponent.{children.@each.name}', 'selectedComponent.{children.@each.redirectUrl}', 'selectedComponent.{mainTitle,checkAction}', 'flowName', 'flow.isValid', function () {
      if (this.get('isWorkflow')) {
        return !this.get('flow.isValid');
      }

      var blocks = this.get('blocks');
      var countActions = blocks.filter(function (c) {
        return c.name === 'Action';
      }).length;
      var countOptions = blocks.filter(function (c) {
        return c.name === 'Options (Single select)';
      }).length;
      var countOptionsChildren = blocks.filter(function (c) {
        return c.name === 'Options (Single select)';
      }).reduce(function (total, item) {
        return total + item.children.length;
      }, 0);
      var actions = blocks.filter(function (c) {
        return c.name === 'Action';
      }).map(function (a) {
        return typeof a.actions === 'string' ? JSON.parse(a.actions).actions[0] : a.actions[0];
      });
      var actionsRules = blocks.filter(function (c) {
        return c.name === 'Action';
      }).map(function (a) {
        return typeof a.actions === 'string' ? JSON.parse(a.actions) : {
          alwaysBtn: a.alwaysBtn,
          rules: a.rules
        };
      });
      return blocks.some(function (c) {
        return !c.mainTitle;
      }) || this.get('flowName') === '' || countOptionsChildren + (countOptions === 0 ? 0 : 1) - countOptions !== countActions || actions.filter(function (a) {
        return !a.checkAction;
      }).length || actionsRules.filter(function (c) {
        return !c.alwaysBtn && !c.rules;
      }).length || blocks.filter(function (c) {
        return c.blockType === 'SLIDER' && c.children.some(function (card) {
          return c.blockType === 'BUTTON' && !card.attachments[0].filename;
        });
      }).length || blocks.filter(function (c) {
        return c.blockType === 'SLIDER' && c.children.some(function (card) {
          return c.blockType === 'BUTTON' && !card.subtitle;
        });
      }).length || blocks.filter(function (c) {
        return c.blockType === 'SLIDER' && c.children.some(function (card) {
          return c.blockType === 'BUTTON' && !card.name;
        });
      }).length || blocks.filter(function (c) {
        return c.blockType === 'SLIDER' && c.children.some(function (card) {
          return c.blockType === 'BUTTON' && !card.redirectUrl;
        });
      }).length;
    }),
    uploadSuccess: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        this.removeAllFiles();
        var hash1 = {
          attachmentId: controller.get('cardAttachmentId')
        };
        var hash2 = {
          blockId: controller.get('cardId'),
          attachments: [result.data.file.attachment.id]
        };
        controller.get('cardAttachmentId') && controller.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', hash1);
        controller.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash2).then(function () {
          var parentId = controller.get('blocks').find(function (block) {
            return block.id === controller.get('cardId');
          }).parent.id;
          controller.updateFlow(parentId);
        });
      };
    }),
    optionsUpload: Ember.computed('blocks.[]', function () {
      var variables = '{"appLabel": "livechat", "model": "flowblockmptt"}';
      var token = this.get("session").get("data.authenticated.access_token") || undefined;
      return {
        paramName: 'file',
        url: _environment.default.apollo.apiURL,
        addRemoveLinks: false,
        autoProcessQueue: true,
        clickable: '.icon-file-icon',
        acceptedFiles: "image/*",
        method: "POST",
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': "uploadAttachment",
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': variables
        }
      };
    }),
    changeParamModule: function changeParamModule(flow) {
      var parent = flow.parent;
      var commentModule = this.get('commentModules').findBy('id', 'tickets');
      var triggerRules = this.get('triggerRules');

      if (parent && (parent.blockType === 'TICKET' || parent.blockType === 'TASK' || triggerRules.findBy('selectedColumn', 'Ticket/Task'))) {
        Ember.set(commentModule, 'disabled', false);
      } else {
        if (parent) {
          this.changeParamModule(parent);
        } else {
          Ember.set(commentModule, 'disabled', true);
        }
      }
    },
    changeParamModuleTags: function changeParamModuleTags(flow) {
      var parent = flow.parent;
      var tagModuleTicket = this.get('tagModules').findBy('id', 'tickets');
      var triggerRules = this.get('triggerRules');

      if (parent && (parent.blockType === 'TICKET' || parent.blockType === 'TASK' || triggerRules.findBy('selectedColumn', 'Ticket/Task'))) {
        Ember.set(tagModuleTicket, 'disabled', false);
      } else {
        if (parent) {
          this.changeParamModuleTags(parent);
        } else {
          Ember.set(tagModuleTicket, 'disabled', true);
        }
      }
    },
    resetCommentModule: function resetCommentModule(flow) {
      var triggerRules = this.get('triggerRules');

      if (flow.module && flow.module.id === 'tickets' && triggerRules.findBy('selectedColumn', 'Conversation')) {
        Ember.set(flow, 'module', {});
      }
    },
    resetView: function resetView() {
      this.set('selectedFlowTab', 'settings');
      this.set('isShowComponent', false);
    },
    actions: {
      backMainPage: function backMainPage() {
        this.transitionToRoute('flows');
      },
      openStartConfigure: function openStartConfigure() {
        this.triggerOpenStartConfigure();
      },
      openConfigure: function openConfigure(component) {
        //if (component.id === this.get('selectedComponent.id')) return;
        this.set('isShowComponent', true);
        this.openConfigureComponent(component.id);

        if (document.querySelector('.txt-input')) {
          setTimeout(function () {
            document.querySelector('.txt-input').innerHTML = component.mainTitle;
          }, 0);
        }
      },
      deleteComponentAll: function deleteComponentAll(component, event) {
        var _this = this;

        event.stopPropagation();

        if (this.get('isWorkflow')) {
          return this.get('workflows').deleteBlock(component, this.get('flow'));
        }

        (0, _jquery.default)('.item-flow').removeClass('active');
        (0, _jquery.default)('.add-component-flow').removeClass('active');
        document.querySelector('.canvas-block').scrollTo(0, 0);
        this.set('isLoadingComponent', true);
        this.ajax.query(_mutation.default.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {
          blockId: component.id
        }).then(function () {
          _this.updateFlow(component.parent && component.parent.id);
        });
      },
      deleteComponent: function deleteComponent(component, event) {
        var _this2 = this;

        event.stopPropagation();

        if (this.get('isWorkflow')) {
          this.get('workflows').deleteBlock(component, this.get('flow')).then(function () {
            _this2.showStatus();

            if (_this2.get('selectedComponent.id') === component.id) {
              _this2.triggerOpenStartConfigure();
            }
          });
        } else {
          this.deleteBlock(component);
        }
      },
      chooseComponent: function chooseComponent(component) {
        var _this3 = this;

        var selectedComponent = this.get('selectedComponent');
        var hash = {
          flowId: this.get('flowId'),
          mainTitle: '',
          blockType: component.blockType,
          name: component.name,
          icon: component.icon
        };
        this.set('isShowComponent', true);
        this.set('isShowListComponents', false);
        this.set('isActiveStartBlockFlow', false);
        this.set('isLoadingComponent', true);
        document.querySelector('.canvas-block').scroll(0, 0);

        if (this.get('isWorkflow')) {
          return this.get('workflows').addBlock(component, selectedComponent, this.get('flow'), function (block) {
            _this3.showStatus();

            _this3.openConfigureComponent(block.id);
          });
        }

        if (component.type === 'data') {
          hash.extraData = JSON.stringify({
            dataCollectorsSelect: []
          });
        } else if (component.type === 'action') {
          var actions = {
            actions: [{
              tag_visitor: [],
              assign_to: null,
              close_conversation: false,
              checkAction: null,
              create_ticket: false
            }],
            actionsFlow: this.get('actionsFlow'),
            rules: [],
            alwaysBtn: false,
            checkCondition: {
              id: 1,
              title: this.intl.t('matches_all_conditions')
            }
          };
          hash.actions = JSON.stringify(actions);
        }

        if (selectedComponent && this.get('blocks').length) {
          hash.prevBlockId = selectedComponent.id;
        }

        if (selectedComponent && selectedComponent.children.length) {
          if (selectedComponent.parent && selectedComponent.blockType !== 'SLIDER' && component.type !== 'single' && component.type !== 'BUTTON') {
            hash.nextBlockId = selectedComponent.children[0].id;
          } else if (!selectedComponent.parent && !selectedComponent.id && this.get('blocks').length) {
            hash.nextBlockId = this.get('blocks').findBy('parent', null).id;
          } else if (selectedComponent.blockType === 'SLIDER' && selectedComponent.children.filter(function (child) {
            return child.blockType !== 'BUTTON';
          })[0]) {
            hash.nextBlockId = selectedComponent.children.filter(function (child) {
              return child.blockType !== 'BUTTON';
            })[0].id;
          }
        } // console.log('CREATE', hash);


        this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function (_ref) {
          var block = _ref.block;

          if (component.type === 'slider') {
            _this3.set('reRender', false);

            _this3.updateCardsComponent(block.id);
          } else if (component.type === 'single') {
            for (var i = 0; i < 2; i++) {
              var childrenId = null;

              if (i === 0) {
                if (selectedComponent && selectedComponent.children.length && selectedComponent.blockType !== 'SLIDER') {
                  childrenId = selectedComponent.children[0].id;
                } else if (_this3.get('isActiveStartBlockFlow') && _this3.get('blocks').length) {
                  childrenId = _this3.get('blocks')[0].id;
                }
              }

              _this3.updateOptionsComponent(block.id, childrenId, i === 1);
            }
          } else {
            _this3.updateFlow(block.id);
          }
        });
      },
      openListComponents: function openListComponents() {
        var component = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var event = arguments.length > 1 ? arguments[1] : undefined;
        event.stopPropagation();

        if (this.get('isCopyState')) {
          this.paperToaster.show('INSERT BLOCK');
          this.set('isCopyState', false);
          var copyBlock = this.get('copyBlock');
          this.insertBlock(copyBlock, component);
          this.set('copyBlock', null);
          return;
        }

        if (component) {
          this.set('selectedComponent', component);
        } else {
          var blocks = this.get('blocks');

          if (this.get('isChatBot')) {
            this.set('selectedComponent', {
              children: blocks[0] ? [{
                id: blocks[0].id
              }] : [],
              parent: null
            });
          } else {
            this.set('selectedComponent', this.get('flow.triggerBlock'));
          }
        }

        this.set('selectedFlowTab', 'configure');
        this.set('isShowListComponents', true);
        this.set('isActiveStartBlockFlow', false);
        this.set('isShowComponent', false);
        (0, _jquery.default)('.item-flow, .item-flow-wrap').removeClass('active');
        (0, _jquery.default)('.add-component-flow').removeClass('active');
        (0, _jquery.default)(event.target).closest('.add-component-flow').addClass('active');
      },
      addCard: function addCard() {
        this.set('reRender', false);
        this.updateCardsComponent(this.get('selectedComponent.id'));
      },
      removeCard: function removeCard(card) {
        var _this4 = this;

        this.set('reRender', false);
        var parentFlow = this.get('blocks').findBy('id', card.parent.id);
        this.ajax.query(_mutation.default.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {
          blockId: card.id
        }).then(function () {
          _this4.mutationFlow();

          _this4.updateFlow(parentFlow.id);
        });
      },
      addOption: function addOption() {
        this.updateOptionsComponent(this.get('selectedComponent.id'));
      },
      removeOption: function removeOption(option) {
        var _this5 = this;

        this.ajax.query(_mutation.default.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {
          blockId: option.id
        }).then(function () {
          _this5.mutationFlow();

          _this5.updateFlow(option.parent.id);
        });
      },
      addRule: function addRule() {
        this.get('selectedComponent.rules').pushObject({});
      },
      removeRule: function removeRule(rule) {
        this.get('selectedComponent.rules').removeObject(rule);
        this.updateActionsComponent();
      },
      addAction: function addAction() {
        this.get('selectedComponent.actions').pushObject({});
      },
      removeAction: function removeAction(action) {
        this.get('selectedComponent.actions').removeObject(action);
        this.updateSelectActions(null, action.checkAction);
        this.updateActionsComponent();
      },
      onSelectField: function onSelectField(field) {
        var _this6 = this;

        var dataCollectorsSelect = this.get('selectedComponent.dataCollectorsSelect');
        dataCollectorsSelect.pushObject(field);
        this.get('dataCollectorsSelect').removeObject(field);
        var hash = {
          flowId: this.get('flowId'),
          blockId: this.get('selectedComponent.id'),
          extraData: JSON.stringify({
            dataCollectorsSelect: dataCollectorsSelect
          })
        };
        this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function () {
          _this6.updateFlow(_this6.get('selectedComponent.id'));
        });
      },
      removeField: function removeField(field) {
        var _this7 = this;

        var dataCollectorsSelect = this.get('selectedComponent.dataCollectorsSelect');
        dataCollectorsSelect.removeObject(field);
        this.get('dataCollectorsSelect').pushObject(field);
        var hash = {
          flowId: this.get('flowId'),
          blockId: this.get('selectedComponent.id'),
          extraData: JSON.stringify({
            dataCollectorsSelect: dataCollectorsSelect
          })
        };
        this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function () {
          _this7.updateFlow(_this7.get('selectedComponent.id'));
        });
      },
      addCardId: function addCardId(card) {
        this.set('cardId', card.id);
        card.attachments[0] && this.set('cardAttachmentId', card.attachments[0].id);
      },
      addTag: function addTag(tag, index) {
        var action = this.get('selectedComponent.actions')[index];
        action.tag_visitor.pushObject(tag);
        this.updateActionsComponent();
      },
      removeTagAtIndex: function removeTagAtIndex(tagIndex, index) {
        var action = this.get('selectedComponent.actions')[index];
        action.tag_visitor.removeAt(tagIndex);
        this.updateActionsComponent();
      },
      onAddRule: function onAddRule() {
        this.get('rulesConditions').pushObject({});
      },
      onRemoveRule: function onRemoveRule(rule) {
        this.get('rulesConditions').removeObject(rule);
      },
      onSelectUser: function onSelectUser(index, value) {
        var action = this.get('selectedComponent.actions')[index];
        Ember.set(action, 'assign_to', value);
        this.updateActionsComponent();
      },
      selectActionCondition: function selectActionCondition(val) {
        this.set('checkCondition', val);
        this.saveConditionsMutation();
      },
      selectColumnRule: function selectColumnRule(index, rule) {
        var ruleOptions = this.get('ruleOptions.variable_type_operators')[rule.field_type];
        var rulesConditions = this.get('rulesConditions')[index];
        Ember.set(rulesConditions, 'selectedColumn', rule);
        Ember.set(rulesConditions, 'ruleOptions', ruleOptions);
        this.saveConditionsMutation();
      },
      selectConditionRule: function selectConditionRule(index, rule) {
        var rulesConditions = this.get('rulesConditions')[index];
        Ember.set(rulesConditions, 'selectedCondition', rule);
        this.saveConditionsMutation();
      },
      selectVariablesActions: function selectVariablesActions(index, rule) {
        var ruleOptions = this.get('ruleOptions.variable_type_operators')[rule.field_type];
        var rulesConditions = this.get('selectedComponent.rules')[index];
        Ember.set(rulesConditions, 'selectedColumn', rule);
        Ember.set(rulesConditions, 'ruleOptions', ruleOptions);
        this.saveConditionsMutation();
      },
      checkActionComponent: function checkActionComponent(index, val) {
        var action = this.get('selectedComponent.actions')[index];
        this.updateSelectActions(val, action.checkAction);
        Ember.set(action, 'checkAction', val);
        this.updateActionsComponent();
      },
      actionCloseStatus: function actionCloseStatus(index, value) {
        var action = this.get('selectedComponent.actions')[index];
        Ember.set(action, 'close_conversation', value);
        this.updateActionsComponent();
      },
      onBlurInputFlow: function onBlurInputFlow(name, val) {
        var hash = _defineProperty({}, name, val);

        if (val && val.length) {
          if (this.get('isChatBot')) {
            this.mutationFlow(hash);
          } else {
            this.mutationWorkflow(hash);
          }
        }
      },
      onBlurInputSlider: function onBlurInputSlider(name, val, blockId) {
        var _hash2;

        var hash = (_hash2 = {}, _defineProperty(_hash2, name, val), _defineProperty(_hash2, "flowId", this.get('flowId')), _defineProperty(_hash2, "blockId", blockId), _hash2);

        if (val && val.length) {
          this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash);
        }
      },
      onBlurRulesAction: function onBlurRulesAction() {
        this.updateActionsComponent();
      },
      toggleAlwaysBtn: function toggleAlwaysBtn(value) {
        var selectedComponent = this.get('selectedComponent');
        Ember.set(selectedComponent, 'alwaysBtn', value);
        this.updateActionsComponent();
      },
      saveConditions: function saveConditions() {
        this.saveConditionsMutation();
      },
      showSaveStatus: function showSaveStatus() {
        this.showStatus();
      },
      selectBranch: function selectBranch(component, index, input) {
        if (input !== true && this.get('inputFocused')) {
          return;
        }

        Ember.set(component.children[index], 'active', true);

        if (index === 0) {
          Ember.set(component.children[1], 'active', false);
        } else {
          Ember.set(component.children[0], 'active', false);
        }

        if (input === true) {
          this.set('inputFocused', true);
        }
      },
      deselectBranch: function deselectBranch(component, index, input) {
        if (input !== true && this.get('inputFocused')) {
          return;
        }

        Ember.set(component.children[index], 'active', false);

        if (input === true) {
          this.set('inputFocused', false);
        }
      },
      updateBranchName: function updateBranchName(component, index, value) {
        Ember.set(component.children[index], 'name', value);
        this.saveBlock(component);
      },
      addIfCondition: function addIfCondition(component, module, operator) {
        if (!component.ifConditions) {
          Ember.set(component, 'ifConditions', []);
        }

        var cond = {};

        if (module) {
          cond.selectedModule = module;
        }

        if (typeof operator === 'string') {
          if (component.ifConditions.length > 0) {
            Ember.set(component.ifConditions[component.ifConditions.length - 1], 'operator', operator);
          }
        } else {
          if (component.ifConditions.length > 0) {
            Ember.set(component.ifConditions[component.ifConditions.length - 1], 'operator', 'AND');
          }
        }

        component.ifConditions.addObject(cond);
        Ember.set(component.children[0], 'active', false);
      },
      addThenCondition: function addThenCondition(component, module, operator) {
        if (!component.thenConditions) {
          Ember.set(component, 'thenConditions', []);
        }

        var cond = {};

        if (module) {
          cond.selectedModule = module;
        }

        if (typeof operator === 'string') {
          if (component.thenConditions.length > 0) {
            Ember.set(component.thenConditions[component.thenConditions.length - 1], 'operator', operator);
          }
        } else {
          if (component.thenConditions.length > 0) {
            Ember.set(component.thenConditions[component.thenConditions.length - 1], 'operator', 'AND');
          }
        }

        component.thenConditions.addObject(cond);
        Ember.set(component.children[1], 'active', false);
      },
      onRemoveIfCondition: function onRemoveIfCondition(cond, component) {
        component.ifConditions.removeObject(cond);

        if (component.ifConditions.length === 1) {
          Ember.set(component.ifConditions[0], 'operator', '');
        }

        this.saveIfElseConditions(component);
      },
      onRemoveThenCondition: function onRemoveThenCondition(cond, component) {
        component.thenConditions.removeObject(cond);

        if (component.thenConditions.length === 1) {
          Ember.set(component.thenConditions[0], 'operator', '');
        }

        this.saveIfElseConditions(component);
      },
      onConditionOperator: function onConditionOperator(component, cond, value) {
        Ember.set(cond, 'operator', value);
        this.saveIfElseConditions(component);
      },
      selectIfThenConditionModule: function selectIfThenConditionModule(cond, value) {
        if (cond.selectedModule !== value) {
          Ember.set(cond, 'selectedRule', '');
          Ember.set(cond, 'selectedOption', '');
        }

        Ember.set(cond, 'selectedModule', value);
      },
      sendEmailPreview: function sendEmailPreview() {
        var _this8 = this;

        var workflowsService = this.get('workflows');
        var hash = {
          accountId: this.get('recipientAccount.pk'),
          to: this.get('recipientEmail')
        };
        var body = this.get('selectedComponent.body');
        var subject = this.get('selectedComponent.subject');
        hash.body = workflowsService.clearVariables(body);
        hash.subject = workflowsService.clearVariables(subject);
        this.ajax.mutation(_mutation.default.SEND_TEST_EMAIL, 'sendTestEmail', hash, function () {
          _this8.paperToaster.show('Email sent, check your mailbox');
        });
      },
      selectTriggerColumn: function selectTriggerColumn(rule, value) {
        var options = [];

        switch (value) {
          case 'Ticket/Task':
            options = ['Is created', 'Is updated', 'Is deleted', 'Is idled'];
            break;

          case 'Conversation':
            options = ['New incoming', 'Is idled', 'Visitor has responded'];
        }

        if (rule.selecteColumn !== value) {
          Ember.set(rule, 'selectedColumn', value);
          Ember.set(rule, 'selectedCondition', '');
          Ember.set(rule, 'ruleOptions', options);
        }
      },
      selectTriggerConditionRule: function selectTriggerConditionRule(rule, value) {
        Ember.set(rule, 'selectedCondition', value);

        if (value !== 'Is idled') {
          this.saveTriggerMutation();
        }
      },
      selectTriggerPeriodTimes: function selectTriggerPeriodTimes(rule, value) {
        Ember.set(rule, 'idledPeriodTimes', value);
        this.saveTriggerMutation();
      },
      saveTrigger: function saveTrigger() {
        this.saveTriggerMutation();
      },
      onAddTriggerRule: function onAddTriggerRule(module, operator) {
        var variables = this.get('triggerOptions.variables');
        var selectedColumn = typeof module === 'string' ? module : '';
        var ruleOptions = [];
        var rules = this.get('triggerRules');

        if (this.get('triggerRules.length') > 0) {
          var firstRuleColumn = this.get('triggerRules.0.selectedColumn');
          variables = [firstRuleColumn];
          selectedColumn = firstRuleColumn;
          ruleOptions = this.get('triggerRules.0.ruleOptions');
          this.set('triggerRules.0.variables', variables);
        }

        switch (selectedColumn) {
          case 'Ticket/Task':
            ruleOptions = ['Is created', 'Is updated', 'Is deleted', 'Is idled'];
            break;

          case 'Conversation':
            ruleOptions = ['New incoming', 'Is idled', 'Visitor has responded'];
        }

        if (typeof operator === 'string') {
          Ember.set(rules[rules.length - 1], 'operator', operator);
        }

        rules.addObject({
          selectedColumn: selectedColumn,
          selectedCondition: '',
          idledPeriod: 0,
          idledPeriodTimes: 'minutes',
          variables: variables,
          operator: '',
          ruleOptions: ruleOptions
        });
      },
      onRemoveTriggerRule: function onRemoveTriggerRule(rule) {
        var rules = this.get('triggerRules');
        rules.removeObject(rule);

        if (rules.length === 1) {
          this.set('triggerRules.0.variables', this.get('triggerOptions.variables'));
        }

        if (rules.length > 0) {
          Ember.set(rules[rules.length - 1], 'operator', '');
        }

        this.saveTriggerMutation();
      },
      saveCursorPosition: function saveCursorPosition() {
        var input = (0, _jquery.default)('.email-subj-input > input');
        var start = input.prop('selectionStart');
        var end = input.prop('selectionEnd');

        if (start || start == '0') {
          this.set('cursor', {
            start: start,
            end: end
          });
        } else {
          this.set('cursor', {
            start: -1,
            end: -1
          });
        }
      },
      addVariable: function addVariable(variable) {
        var input = (0, _jquery.default)('.email-subj-input > input');
        var cursor = this.get('cursor');

        if (!cursor || cursor.start < 0) {
          input.val(input.val() + '{{' + variable + '}}');
        } else {
          var value = input.val();
          var before = value.substring(0, cursor.start);
          var after = value.substring(cursor.end);
          input.val(before + ' {{' + variable + '}} ' + after);
        }

        this.set('selectedComponent.subject', input.val());
        this.set('selectedComponent.mainTitle', input.val());
        input.focus();
        this.saveBlock(this.get('selectedComponent'));
      },
      addBodyVariable: function addBodyVariable(variable) {
        this.set('selectedComponent.body', this.get('selectedComponent.body') + '{{' + variable + '}}');
        this.saveBlock(this.get('selectedComponent'));
      },
      addToVariable: function addToVariable(variable) {
        this.set('selectedComponent.to', '{{' + variable + '}}');
        this.saveBlock(this.get('selectedComponent'));
      },
      updateEmailParams: function updateEmailParams(component, name, value) {
        if (name === 'subject') {
          var parts = value.split('{{');
          var subjVariables = this.get('subjVariables');
          parts.forEach(function (p, i) {
            if (p.indexOf('}}') === p.length - 2) {
              if (subjVariables.indexOf(p.substring(0, p.length - 2)) < 0) {
                parts[i] = '';
              }
            } else {
              if (p.indexOf('{') >= 0) {
                var tmp = p.split('{');
                parts[i] = tmp.join('');
              }

              if (parts[i].indexOf('}') >= 0) {
                var _tmp = parts[i].split('}');

                parts[i] = _tmp.join('');
              }
            }
          });
          Ember.set(component, 'mainTitle', value);
        }

        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      updateTargetChatParams: function updateTargetChatParams(component, name, value) {
        Ember.set(component, name, value);

        if (name === 'description') {
          Ember.set(component, 'mainTitle', value);
        }

        if (name === 'users' || name === 'rooms') {
          Ember.set(component, 'recipients', value);
        }

        if (name === 'model') {
          var modelValue = value.id === 'users' ? this.get('selectedComponent.users') : this.get('selectedComponent.rooms');
          Ember.set(component, 'recipients', modelValue);
        }

        this.saveBlock(component);
      },
      updateAddCommentParams: function updateAddCommentParams(component, name, value) {
        Ember.set(component, name, value);

        if (name === 'comment') {
          Ember.set(component, 'mainTitle', value);
        }

        this.saveBlock(component);
      },
      updateTicketParams: function updateTicketParams(component, name, value) {
        Ember.set(component, name, value);

        if (name === 'subject') {
          Ember.set(component, 'mainTitle', value);
        }

        if (name === 'department') {
          this.updateDepartmentUsers(value.id);
        }

        this.saveBlock(component);
      },
      removeTagItem: function removeTagItem(component, tag) {
        if (this.get('flow.isActive')) {
          return;
        }

        component.tags && component.tags.removeObject(tag);
        this.saveBlock(component);
      },
      addTagToTicket: function addTagToTicket(component) {
        var tag = this.get('newTag');

        if (tag) {
          if (!component.tags) {
            Ember.set(component, 'tags', []);
          }

          component.tags.addObject(tag);
          this.saveBlock(component);
        }
      },
      addTagToBlock: function addTagToBlock(component, tag) {
        if (component.tag_list !== undefined) {
          component.tag_list.pushObject(tag);
        } else {
          Ember.set(component, 'tag_list', []);
          component.tag_list.pushObject(tag);
        }

        this.saveBlock(component);
      },
      removeTag: function removeTag(component, tagIndex) {
        component.tag_list.removeAt(tagIndex);
        this.saveBlock(component);
      },
      updateContactParams: function updateContactParams(component, name, value) {
        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      updateTagParams: function updateTagParams(component, name, value) {
        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      updateStatusParams: function updateStatusParams(component, name, value) {
        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      updateDueDateParams: function updateDueDateParams(component, name, value) {
        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      onBlurInputMessage: function onBlurInputMessage(blockId, mainTitle) {
        var _this9 = this;

        var block = this.get('blocks').findBy('id', blockId);
        var hash = {
          flowId: this.get('flowId'),
          mainTitle: mainTitle,
          blockId: blockId
        };
        Ember.set(block, 'mainTitle', mainTitle);

        if (this.get('isChatBot')) {
          this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function () {
            _this9.showStatus();

            _this9.mutationFlow();
          });
        } else {
          if (block.blockType === 'IFELSE') {
            Ember.set(block, 'description', mainTitle);
          }

          this.saveBlock(block);
        }
      },
      duplicatePath: function duplicatePath(component, event) {
        event.stopPropagation();

        var copyBlock = _objectSpread({}, component);

        copyBlock.id = 'copy';
        copyBlock.originalComponent = component;
        this.set('copyBlock', copyBlock);
        this.get('blocks').addObject(copyBlock);
        this.set('isCopyState', true);
        var ele = document.querySelector('.canvas-block');
        var position = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          x: event.clientX,
          y: event.clientY
        };
        setTimeout(function () {
          (0, _jquery.default)('#flow-block-copy').css({
            top: event.clientY + position.top - 80 + 'px',
            left: event.clientX + position.left - 170 + 'px'
          });
        }, 200);

        function move(event) {
          var position = {
            left: ele.scrollLeft,
            top: ele.scrollTop,
            x: event.clientX,
            y: event.clientY
          };
          (0, _jquery.default)('#flow-block-copy').css({
            top: event.clientY + position.top - 80 + 'px',
            left: event.clientX + position.left - 170 + 'px'
          });
        }

        (0, _jquery.default)('.canvas-block').on('mousemove', move);
      }
    },
    saveIfElseConditions: function saveIfElseConditions(component) {
      this.saveBlock(component);
    },
    updateDepartmentUsers: function updateDepartmentUsers(departmentId) {
      var _this10 = this;

      var propertyId = this.get('flow.properties.0.id');

      if (!propertyId || !departmentId) {
        return;
      }

      var hash = {
        departments: [departmentId],
        propertyIds: [propertyId]
      };
      this.ajax.query(_query.default.HAVE_TASK_ACCESS, 'haveAccess', hash).then(function (result) {
        _this10.set('departmentUsers', result);
      });
    },
    saveBlock: function saveBlock(block) {
      var _this11 = this;

      if (this.get('saveTimer')) clearTimeout(this.get('saveTimer'));
      this.set('saveTimer', setTimeout(function () {
        _this11.get('workflows').saveBlock(block, _this11.get('flow'), function () {
          _this11.showStatus();
        });
      }), 1000);
    },
    updateSelectActions: function updateSelectActions(action, checkAction) {
      var actionsFlow = this.get('selectedComponent.actionsFlow');
      actionsFlow.removeObject(action);
      checkAction && actionsFlow.addObject(checkAction);
    },
    saveTriggerMutation: function saveTriggerMutation() {
      var triggerRules = this.get('triggerRules');
      var conditions = {};
      var any = [];
      var all = [];
      var objectType;
      triggerRules.forEach(function (r, index) {
        var rule = {};

        if (r.selectedColumn === 'Ticket/Task') {
          objectType = 'TICKET';
          rule.name = 'age';

          switch (r.selectedCondition) {
            case 'Is created':
              rule.operator = 'equal_to';
              rule.value = 'created';
              break;

            case 'Is updated':
              rule.operator = 'equal_to';
              rule.value = 'updated';
              break;

            case 'Is deleted':
              rule.operator = 'equal_to';
              rule.value = 'deleted';
              break;

            case 'Is idled':
              rule.name = 'idled';
              rule.operator = 'greater_than';
              rule.value = Math.floor(r.idledPeriod) * (r.idledPeriodTimes === 'days' ? 3600 : r.idledPeriodTimes === 'hours' ? 60 : 1);
          }
        } else if (r.selectedColumn === 'Conversation') {
          objectType = 'CONVERSATION';

          switch (r.selectedCondition) {
            case 'New incoming':
              rule.name = 'is_new_incomming';
              rule.operator = 'is_true';
              break;

            case 'Visitor has responded':
              rule.name = 'visitor_has_responded';
              rule.operator = 'is_true';
              break;

            case 'Is idled':
              rule.name = 'idled';
              rule.operator = 'greater_than';
              rule.value = Math.floor(r.idledPeriod) * (r.idledPeriodTimes === 'days' ? 3600 : r.idledPeriodTimes === 'hours' ? 60 : 1);
              rule.module = 'Conversation';
          }
        }

        if (triggerRules[index - 1]) {
          if (triggerRules[index - 1].operator === 'AND') {
            all.addObject(rule);
          } else {
            any.addObject(rule);
          }
        } else {
          if (r.operator === 'AND') {
            all.addObject(rule);
          } else {
            any.addObject(rule);
          }
        }
      });

      if (any.length) {
        conditions.any = any;
      }

      if (all.length) {
        conditions.all = all;
      }

      Ember.set(this.get('flow.trigger'), 'conditions', conditions);
      this.get('workflows').updateTrigger(this.get('flow'), this.get('flow.objectType') !== objectType ? objectType : '');
    },
    saveConditionsMutation: function saveConditionsMutation() {
      var _this12 = this;

      var flowId = this.get('flowId');
      var ruleId = this.get('ruleId');
      var rules = this.get('rulesConditions');
      var checkCondition = this.get('checkCondition');
      var ruleStruct = this.get('ruleStruct');
      var convertedRules = [];
      rules.forEach(function (item) {
        convertedRules.push({
          name: item.selectedColumn ? item.selectedColumn.name : '',
          operator: item.selectedCondition ? item.selectedCondition.name : '',
          value: parseFloat(item.selectedConditionValue) || item.selectedConditionValue || ''
        });
      });
      ruleStruct.conditions.all = convertedRules;
      ruleStruct.actions.push({
        name: 'send_chat_flow',
        params: {
          flow_id: Number(flowId)
        }
      });
      ruleStruct.checkCondition = checkCondition;
      var hashRule = {
        name: this.get('flowName'),
        description: '',
        isActive: this.get('isActiveFlow'),
        rule: JSON.stringify(ruleStruct),
        flowId: Number(flowId)
      };

      if (ruleId) {
        hashRule.ruleId = ruleId;
      }

      console.log('SAVE COND', hashRule); // return;

      this.ajax.mutation(_mutation.default.CREATE_UPDATE_RULE, 'createOrUpdateRule', hashRule, function (data) {
        console.log('RET', data);

        _this12.set('ruleId', data.rule.id);
      });
    },
    updateActionsComponent: function updateActionsComponent() {
      var _this13 = this;

      var selectedActions = this.get('selectedComponent.actions');
      var actionsFlow = this.get('selectedComponent.actionsFlow');
      var hash = {
        flowId: this.get('flowId'),
        blockId: this.get('selectedComponent.id')
      };
      var rules = this.get('selectedComponent.rules');
      var checkCondition = this.get('selectedComponent.checkCondition');
      var conditions = [];
      rules && rules.forEach(function (item) {
        conditions.push({
          name: item.selectedColumn ? item.selectedColumn.name : '',
          operator: item.selectedCondition ? item.selectedCondition.name : '',
          value: isNaN(Number(item.selectedConditionValue)) ? item.selectedConditionValue : Number(item.selectedConditionValue)
        });
      });
      var action = {
        rules: conditions,
        checkCondition: checkCondition,
        actions: selectedActions,
        actionsFlow: actionsFlow,
        alwaysBtn: this.get('selectedComponent.alwaysBtn')
      };
      hash.actions = JSON.stringify(action);
      this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function () {
        _this13.mutationFlow();

        _this13.updateFlow(_this13.get('selectedComponent.id'));
      });
    },
    updateCardsComponent: function updateCardsComponent(prevBlockId) {
      var _this14 = this;

      var hashSlider = {
        flowId: this.get('flowId'),
        blockType: 'BUTTON',
        buttonType: 'URL',
        prevBlockId: prevBlockId
      }; // console.log('UPDATE CARD', hashSlider);

      return this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hashSlider).then(function () {
        _this14.mutationFlow();

        _this14.updateFlow(prevBlockId);
      });
    },
    updateOptionsComponent: function updateOptionsComponent(prevBlockId) {
      var _this15 = this;

      var childrenId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var isUpdate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var hashOption = {
        flowId: this.get('flowId'),
        blockType: 'BUTTON',
        buttonType: 'NEXT',
        prevBlockId: prevBlockId
      };

      if (childrenId) {
        hashOption.nextBlockId = childrenId;
      } // console.log('UPDATE OPTIONS', hashOption);


      return this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hashOption).then(function (_ref2) {
        var block = _ref2.block;

        if (isUpdate) {
          _this15.mutationFlow();

          _this15.updateFlow(block.id);
        }
      });
    },
    triggerOpenStartConfigure: function triggerOpenStartConfigure() {
      (0, _jquery.default)('.item-flow').removeClass('active');
      (0, _jquery.default)('.add-component-flow').removeClass('active');
      (0, _jquery.default)('.item-flow-wrap').addClass('active');
      this.set('isShowComponent', false);
      this.set('isShowListComponents', false);
      this.set('isActiveStartBlockFlow', true);
      this.set('selectedFlowTab', 'configure');
    },
    mouseDownHandler: function mouseDownHandler(e) {
      // console.log('MOUSE DOWN', e);
      // this.get('isCopyState') && console.log('COPY STATE');
      if (this.get('isCopyState') && e.button === 2) {
        e.preventDefault();
        e.stopPropagation();
        this.set('isCopyState', false);
        this.get('blocks').removeObject(this.get('copyBlock'));
        this.set('copyBlock', null);
        (0, _jquery.default)('.canvas-block').off('mousemove');
        return;
      }

      var ele = document.querySelector('.canvas-block');
      var position = {
        left: ele.scrollLeft,
        top: ele.scrollTop,
        x: e.clientX,
        y: e.clientY
      };
      ele.style.cursor = 'grabbing';
      ele.style.userSelect = 'none';

      function move(e) {
        var dx = e.clientX - position.x;
        var dy = e.clientY - position.y;
        ele.scrollTop = position.top - dy;
        ele.scrollLeft = position.left - dx;
      }

      function up() {
        (0, _jquery.default)('.canvas-block').off('mousemove', move);
        (0, _jquery.default)('.canvas-block').off('mouseup'); // document.removeEventListener('mousemove', move);
        // document.removeEventListener('mouseup', up);

        ele.style.cursor = 'grab';
        ele.style.removeProperty('user-select');
      }

      (0, _jquery.default)('.canvas-block').on('mousemove', move);
      (0, _jquery.default)('.canvas-block').on('mouseup', up); // document.addEventListener('mousemove', move);
      // document.addEventListener('mouseup', up);
    },
    dragCanvas: function dragCanvas() {
      var _this16 = this;

      (0, _jquery.default)('.canvas-block').on('mousedown', function (e) {
        return _this16.mouseDownHandler(e);
      }); // document.addEventListener('mousedown', (e) => this.mouseDownHandler(e));
    },
    checkChildren: function checkChildren(activeBlock, newBlocks, dataCollectors) {
      var _activeBlock$children,
          _this17 = this;

      var child = newBlocks.findBy('id', (_activeBlock$children = activeBlock.children[0]) === null || _activeBlock$children === void 0 ? void 0 : _activeBlock$children.id);

      while (child && dataCollectors.length) {
        var _child$children$;

        if (child.blockType === 'FORM') {
          var extraData = JSON.parse(child.extraData);
          extraData.dataCollectorsSelect.forEach(function (d) {
            dataCollectors.removeObject(dataCollectors.findBy('id', d.id));
          });
        }

        if (child.children.length > 1) {
          child.children.forEach(function (c) {
            _this17.checkChildren(c, newBlocks, dataCollectors);
          });
          break;
        }

        child = newBlocks.findBy('id', (_child$children$ = child.children[0]) === null || _child$children$ === void 0 ? void 0 : _child$children$.id);
      }
    },
    openConfigureComponent: function openConfigureComponent(id) {
      var _this18 = this;

      var blocks = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var newBlocks = blocks || this.get('blocks');
      var prevActiveBlock = newBlocks.findBy('id', this.get('selectedComponent.id'));
      var activeBlock = newBlocks.findBy('id', id); // console.log('CONFIG BLOCK', activeBlock);

      this.set('cursor', {});
      (0, _jquery.default)('.item-flow-wrap').removeClass('active');
      (0, _jquery.default)('.add-component-flow').removeClass('active');
      prevActiveBlock && Ember.set(prevActiveBlock, 'active', false);
      this.set('selectedFlowTab', 'configure');
      this.set('isShowListComponents', false);
      this.set('isActiveStartBlockFlow', false);

      if (activeBlock) {
        if (activeBlock.blockType === 'FORM') {
          (function () {
            var extraData = JSON.parse(activeBlock.extraData);
            Ember.set(activeBlock, 'dataCollectorsSelect', extraData.dataCollectorsSelect);

            var dataCollectors = _toConsumableArray(_this18.get('dataCollectors'));

            var parent = activeBlock;

            while (parent && dataCollectors.length) {
              var _parent$parent;

              if (parent.blockType === 'FORM') {
                var _extraData = JSON.parse(parent.extraData);

                _extraData.dataCollectorsSelect.forEach(function (d) {
                  dataCollectors.removeObject(dataCollectors.findBy('id', d.id));
                });
              }

              parent = newBlocks.findBy('id', (_parent$parent = parent.parent) === null || _parent$parent === void 0 ? void 0 : _parent$parent.id);
            }

            _this18.checkChildren(activeBlock, newBlocks, dataCollectors);

            _this18.set('dataCollectorsSelect', dataCollectors);
          })();
        } else if (activeBlock.name === 'Action') {
          var ruleOptions = this.get('ruleOptions');

          if (typeof activeBlock.actions === 'string') {
            (function () {
              var extraData = JSON.parse(activeBlock.actions);
              Ember.set(activeBlock, 'actions', extraData.actions);
              Ember.set(activeBlock, 'alwaysBtn', extraData.alwaysBtn);
              Ember.set(activeBlock, 'checkCondition', extraData.checkCondition);
              Ember.set(activeBlock, 'actionsFlow', extraData.actionsFlow);
              Ember.set(activeBlock, 'rules', []);

              if (extraData.rules.length) {
                extraData.rules.forEach(function (item) {
                  Object.keys(item).forEach(function (key) {
                    if (typeof item[key] === 'string') item[key] = item[key].replace('_', ' ');
                  });
                });

                var _loop = function _loop(i) {
                  var rulesConditions = {};
                  var fieldType = ruleOptions.variables.filter(function (el) {
                    return el.name === extraData.rules[i].name.replace(' ', '_');
                  });
                  fieldType.length && ruleOptions.variable_type_operators[fieldType[0].field_type].forEach(function (val) {
                    if (val.name === extraData.rules[i].operator.replace(' ', '_')) {
                      rulesConditions.selectedCondition = val;
                    }
                  });
                  rulesConditions.ruleOptions = ruleOptions.variable_type_operators[fieldType[0].field_type];
                  ruleOptions.variables.forEach(function (val) {
                    if (val.name === extraData.rules[i].name.replace(' ', '_')) {
                      rulesConditions.selectedColumn = val;
                    }
                  });
                  rulesConditions.selectedConditionValue = extraData.rules[i].value;
                  activeBlock.rules.pushObject(rulesConditions);
                };

                for (var i = 0; i < extraData.rules.length; i++) {
                  _loop(i);
                }
              }
            })();
          }
        } else if (activeBlock.blockType === 'BUTTON') {
          var parentFlow = newBlocks.findBy('id', activeBlock.parent.id);

          if (parentFlow) {
            activeBlock = parentFlow;
          }
        } else if (activeBlock.blockType === 'SLIDER') {
          Ember.set(activeBlock, 'children', activeBlock.children.filter(function (child) {
            return child.blockType === 'BUTTON';
          }));
        } else if (activeBlock.blockType === 'COMMENT') {
          this.changeParamModule(activeBlock);
          this.resetCommentModule(activeBlock);
        } else if (activeBlock.blockType === 'TAG') {
          this.changeParamModuleTags(activeBlock);
          this.resetCommentModule(activeBlock);
        }

        Ember.set(activeBlock, 'active', true);
        this.set('selectedComponent', activeBlock);
      } else {
        this.set('selectedComponent', null);
        this.set('isActiveStartBlockFlow', true);
        this.triggerOpenStartConfigure();
      }

      this.set('isLoadingComponent', false);
      setTimeout(function () {
        var activeElement = document.querySelector('.item-flow.active');

        if (document.getElementById('editor')) {
          document.getElementById('editor').innerHTML = activeBlock.mainTitle;
        }

        _this18.set('reRender', true); // activeElement && activeElement.scrollIntoView({ behavior: 'smooth' });

      }, 500);
    },
    showStatus: function showStatus() {
      var _this19 = this;

      this.set('showStatusSave', true);
      setTimeout(function () {
        _this19.set('showStatusSave', false);
      }, 2000);
    },
    updateFlow: function updateFlow(id) {
      var _this20 = this;

      this.ajax.query(_query.default.CHATBOT, 'chatbot', {
        flowId: this.get('flowId')
      }).then(function (data) {
        if (data.extraData) {
          var extraData = JSON.parse(data.extraData);
          data.isDisabled = Boolean(extraData.isDisabled);
          data.isTemplate = Boolean(extraData.isTemplate);
        }

        _this20.set('flow', data); // this.set('blocks', data.blocks);


        _this20.set('blocks', _this20.deserializeFlow(data.blocks));

        _this20.showStatus();

        _this20.updateDataCollectorsSelect(data.blocks);

        _this20.openConfigureComponent(id, data.blocks);

        _this20.isDisabledFlowActive;
        setTimeout(function () {
          var activeElement = document.querySelector('.item-flow.active'); // this.set('reRender', true);

          activeElement && activeElement.scrollIntoView({
            behavior: 'smooth'
          });
        }, 200);
      });
    },
    updateDataCollectorsSelect: function updateDataCollectorsSelect(blocks) {
      var filterBlocks = [];
      blocks.forEach(function (block) {
        if (block.blockType === 'FORM') {
          var data = JSON.parse(block.extraData).dataCollectorsSelect;

          if (!Array.isArray(data)) {
            data = [];
          }

          filterBlocks.push(data);
        }
      });
      this.set('dataCollectorsSelect', this.get('dataCollectors').filter(function (a) {
        return !filterBlocks.some(function (b) {
          return b.id === a.id;
        });
      }));
    },
    mutationFlow: function mutationFlow() {
      var _this21 = this;

      var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var extraData = {
        isDisabled: this.get('isDisabledFlowActive'),
        isTemplate: this.get('flow.isTemplate')
      };

      var data = _objectSpread(_objectSpread({}, hash), {}, {
        flowId: this.get('flowId'),
        extraData: JSON.stringify(extraData)
      });

      this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', data).then(function () {
        _this21.showStatus();
      });
    },
    mutationWorkflow: function mutationWorkflow() {
      var _this22 = this;

      var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (hash.title) {
        hash.name = hash.title;
      }

      var data = _objectSpread(_objectSpread({}, hash), {}, {
        workflowId: this.get('flow.id')
      });

      return this.ajax.mutation(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', data).then(function () {
        _this22.showStatus();
      });
    },
    deserializeFlow: function deserializeFlow(bl) {
      var blocks = [];
      bl.forEach(function (b) {
        if (b.parent) {
          var p = bl.findBy('id', b.parent.id);

          if (p) {
            b.parent = p;
          }
        }

        var children = [];
        b.children.forEach(function (c) {
          c = bl.findBy('id', c.id);

          if (c) {
            children.addObject(c);
          }
        });

        if (!b.parent) {// console.log('SET CHILDREN', b, children);
        }

        b.children = children.sortBy('id');
        blocks.addObject(b);
      }); // console.log('DESER FLOW', blocks);

      return blocks;
    },
    insertBlock: function insertBlock(copyBlock, component) {
      var _this23 = this;

      if (component) {
        // console.log('INSERT', copyBlock, component);
        var hash = {
          flowId: this.get('flowId'),
          mainTitle: copyBlock.mainTitle,
          blockType: copyBlock.blockType,
          name: copyBlock.name,
          icon: copyBlock.icon,
          prevBlockId: component.id
        };

        if (copyBlock.blockType === 'FORM') {
          hash.extraData = JSON.stringify({
            dataCollectorsSelect: []
          });
        } else if (copyBlock.icon === 'play') {
          var actions = {
            actions: [{
              tag_visitor: [],
              assign_to: null,
              close_conversation: false,
              checkAction: null,
              create_ticket: false
            }],
            actionsFlow: this.get('actionsFlow'),
            rules: [],
            alwaysBtn: false,
            checkCondition: {
              id: 1,
              title: this.intl.t('matches_all_conditions')
            }
          };
          hash.actions = JSON.stringify(actions);
        }

        if (component.children[0]) {
          hash.nextBlockId = component.children[0].id;
        }

        if (component.children.length) {
          if (component.parent && component.blockType !== 'SLIDER' && copyBlock.icon === 'options-single' && copyBlock.blockType !== 'BUTTON') {
            hash.nextBlockId = component.children[0].id;
          } else if (!component.parent && !component.id && this.get('blocks').length) {
            hash.nextBlockId = this.get('blocks').findBy('parent', null).id;
          } else if (component.blockType === 'SLIDER' && component.children.filter(function (child) {
            return child.blockType !== 'BUTTON';
          })[0]) {
            hash.nextBlockId = component.children.filter(function (child) {
              return child.blockType !== 'BUTTON';
            })[0].id;
          }
        } // console.log('SAVE BLOCK', hash);


        this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function (_ref3) {
          var block = _ref3.block;
          console.log('BLOCK', block);

          if (copyBlock.blockType === 'SLIDER') {
            var requests = [];

            _this23.set('reRender', false);

            console.log('SLIDER', copyBlock.children);
            copyBlock.children.forEach(function (c) {
              if (c.blockType === 'BUTTON') {
                var hashSlider = {
                  flowId: _this23.get('flowId'),
                  blockType: 'BUTTON',
                  buttonType: 'URL',
                  // prevBlockId: 'copy',
                  prevBlockId: block.id,
                  attachments: c.attachments.map(function (a) {
                    return a.id;
                  }),
                  buttonText: c.buttonText,
                  mainTitle: c.mainTitle,
                  redirectUrl: c.redirectUrl,
                  subtitle: c.subtitle,
                  name: c.name
                };
                console.log('UPDATE CARD', hashSlider);
                requests.addObject(_this23.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hashSlider));
              }
            });

            if (component.children[0]) {
              var _hash3 = {
                flowId: _this23.get('flowId'),
                blockId: +component.children[0].id,
                prevBlockId: block.id
              };
              requests.addObject(_this23.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', _hash3));
            }

            Ember.RSVP.all(requests).then(function () {
              _this23.updateFlow(block.id);
            });
          } else if (copyBlock.icon === 'options-single') {
            console.log('OPTIONS', copyBlock.children);
            var _requests = [];
            var prevBlockId = block.id;
            copyBlock.children.forEach(function (c, i) {
              var childrenId = null;

              if (i === 0) {
                if (component && component.children.length && component.blockType !== 'SLIDER') {
                  childrenId = component.children[0].id;
                } else if (_this23.get('isActiveStartBlockFlow') && _this23.get('blocks').length) {
                  childrenId = _this23.get('blocks')[0].id;
                }
              }

              var hashOption = {
                flowId: _this23.get('flowId'),
                blockType: 'BUTTON',
                buttonType: 'NEXT',
                prevBlockId: prevBlockId,
                mainTitle: c.mainTitle
              };

              if (childrenId) {
                hashOption.nextBlockId = childrenId;
              }

              console.log('UPDATE OPTIONS', hashOption);

              _requests.addObject(_this23.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hashOption));
            });
            Ember.RSVP.all(_requests).then(function () {
              _this23.updateFlow(block.id);
            });
          } else {
            _this23.updateFlow(block.id);
          }
        });
      } else {
        var firstBlock = this.get('firstBlock');
        console.log('INSERT FIRST', copyBlock);
        var _hash4 = {
          flowId: this.get('flowId'),
          mainTitle: copyBlock.mainTitle,
          blockType: copyBlock.blockType,
          name: copyBlock.name,
          icon: copyBlock.icon,
          nextBlockId: +firstBlock.id
        };

        if (copyBlock.blockType === 'FORM') {
          _hash4.extraData = JSON.stringify({
            dataCollectorsSelect: []
          });
        }

        console.log('SAVE BLOCK', _hash4);
        return;
        this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', _hash4).then(function (_ref4) {
          var block = _ref4.block;
          console.log('BLOCK', block);
          var hash2 = {
            flowId: _this23.get('flowId'),
            blockId: +firstBlock.id,
            mainTitle: firstBlock.mainTitle,
            prevBlockId: +block.id
          };
          console.log('UPDATE PREV', hash2); // this.ajax.query(Mutation.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then((res) => {
          //     console.log('RES', res.block);

          _this23.updateFlow(block.id); // });

        });
        /*
                            if (component.type === 'data') {
                                hash.extraData = JSON.stringify({dataCollectorsSelect: []});
                            } else if (component.type === 'action') {
                                const actions = {
                                    actions: [{
                                        tag_visitor: [],
                                        assign_to: null,
                                        close_conversation: false,
                                        checkAction: null,
                                        create_ticket: false,
                                    }],
                                    actionsFlow: this.get('actionsFlow'),
                                    rules: [],
                                    alwaysBtn: false,
                                    checkCondition: {id: 1, title: this.intl.t('matches_all_conditions')},
                                };
                                hash.actions = JSON.stringify(actions);
                            }
                             if (selectedComponent && this.get('blocks').length) {
                                hash.prevBlockId = selectedComponent.id;
                            }
                             if (selectedComponent && selectedComponent.children.length) {
                                if (selectedComponent.parent && selectedComponent.blockType !== 'SLIDER' &&
                                    component.type !== 'single' && component.type !== 'BUTTON') {
                                    hash.nextBlockId = selectedComponent.children[0].id;
                                } else if (!selectedComponent.parent && !selectedComponent.id && this.get('blocks').length) {
                                    hash.nextBlockId = this.get('blocks').findBy('parent', null).id;
                                } else if (selectedComponent.blockType === 'SLIDER' && selectedComponent.children.filter(child => child.blockType !== 'BUTTON')[0]) {
                                    hash.nextBlockId = selectedComponent.children.filter(child => child.blockType !== 'BUTTON')[0].id;
                                }
                            }
                             this.ajax.query(Mutation.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(({block}) => {
                                if (component.type === 'slider') {
                                    this.set('reRender', false);
                                    this.updateCardsComponent(block.id);
                                } else if (component.type === 'single') {
                                    for (let i = 0; i < 2; i++) {
                                        let childrenId = null;
                                         if (i === 0) {
                                            if (selectedComponent && selectedComponent.children.length && selectedComponent.blockType !== 'SLIDER') {
                                                childrenId = selectedComponent.children[0].id;
                                            } else if (this.get('isActiveStartBlockFlow') && this.get('blocks').length) {
                                                childrenId = this.get('blocks')[0].id;
                                            }
                                        }
                                        this.updateOptionsComponent(block.id, childrenId, i === 1);
                                    }
                                } else {
                                    this.updateFlow(block.id);
                                }
                            });
                             /**/
      }

      this.get('blocks').removeObject(copyBlock);
    },
    deleteBlock: function deleteBlock(component) {
      var _this24 = this;

      if (component.blockType === 'SLIDER') {
        console.log('DELETE SLIDER', component);
      } else if (component.icon === 'options-single') {
        console.log('DELETE OPTIONS', component);
      } else if (component.children[0]) {
        console.log('DELETE', component);
        var hash = {
          flowId: this.get('flowId'),
          blockId: +component.children[0].id
        };

        if (component.parent) {
          hash.prevBlockId = +component.parent.id;
        } else {
          hash.prevBlockId = 0;
        }

        console.log('UPDATE BLOCK', hash, component);
        this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function (_ref5) {
          var block = _ref5.block;
          console.log('BLOCK', block);

          if (component.parent) {
            var hash2 = {
              flowId: _this24.get('flowId'),
              blockId: +component.parent.id,
              nextBlockId: +component.children[0].id
            };
            console.log('UPDATE PREV', hash2);

            _this24.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function (res) {
              console.log('RES', res.block);

              _this24.ajax.query(_mutation.default.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {
                blockId: component.id
              }).then(function () {
                _this24.updateFlow();
              });
            });
          } else {
            // const hash2 = {
            //     flowId: this.get('flowId'),
            //     blockId: +component.id,
            //     nextBlockId: 0
            // };
            // console.log('UPDATE CUR', hash2);
            // this.ajax.query(Mutation.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then((res) => {
            //     console.log('RES', res.block);
            //     this.ajax.query(Mutation.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {blockId: component.id}).then(() => {
            //         this.updateFlow();
            // });
            // });
            _this24.ajax.query(_mutation.default.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {
              blockId: component.id
            }).then(function () {
              _this24.updateFlow();
            });
          }
        });
      } else {
        this.ajax.query(_mutation.default.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {
          blockId: component.id
        }).then(function () {
          _this24.updateFlow();
        });
      }
    }
  });

  _exports.default = _default;
});